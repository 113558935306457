import React,{useState,useRef,useEffect} from 'react'
import { Formik, Field } from 'formik'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import { DropdownComponent } from '../../components/options'
import { ValidateForm } from '../../validations/schemaInquiry'


export default function TestEmailComponent(props){


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(false);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
          HANDLE SUBMIT FORM
  -------------------------------------------------------*/

  const [state, setState] = useState({
    type:'__',
    message:'Message will be shown here. Please click the button below.'
  });

  function handleSubmitForm(){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:"Sending test email. Please, wait..."}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','test_email');
    axios.post('/portal/___test_email/___test_email.php',formData)
    .then(function (response) {
      let obj = response.data;
      setState(obj);
      setspinner(false);
    })
    .catch(function (error){
      setloader(false);
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
    });

  }



  useEffect(()=>{

  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
       {loader&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}


       <div className="appWrapper-data">
          <div className="appWrapper-grid">

         <div className="appWrapper-title flex">
            <div>
              <h2>Test Email</h2>
              <p>For Developers use only!!!</p>
            </div>
            <div>
               <Button className='BasicButton' size='tiny' content='Refresh'  icon='sync alternate icon' onClick={()=>WindowReload()}/>
            </div>
         </div>

        {/*---------------------------------------------------------
            TABLE LIST SECTION
           --------------------------------------------------------*/}
        {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}


          <div className="FormWrapper">
     
                    <div className="panelForm">
                       <div className="panelForm-body">

                          {state.type=='success'&&<div class="alert-message left success" style={{'margin':'10px 0'}}>
                              <div><i class="info circle icon"></i></div>
                              <div><p>{state.message}</p></div>
                          </div>}

                          {state.type=='error'&&<div class="alert-message left error" style={{'margin':'10px 0'}}>
                              <div><i class="info circle icon"></i></div>
                              <div><p>{state.message}</p></div>
                          </div>}

                          {state.type=='failed'&&<div class="alert-message left error" style={{'margin':'10px 0'}}>
                              <div><i class="info circle icon"></i></div>
                              <div><p>{state.message}</p></div>
                          </div>}

                          {state.type=='__'&&<div class="alert-message left info" style={{'margin':'10px 0'}}>
                              <div><i class="info circle icon"></i></div>
                              <div><p>{state.message}</p></div>
                          </div>}

                       </div>
                       <div className="panelForm-footer">
                           <Button className='PrimaryButton uppercase strong' content='Send Test Email' onClick={()=>handleSubmitForm()}/>
                       </div>
                    </div>{/*.panelForm*/}

    
             </div>

        </div>{/*.appWrapper-grid*/}
      </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
