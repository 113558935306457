import React,{useState,useEffect} from 'react'
import { Icon } from 'semantic-ui-react'
import { WindowURL } from '../services/redirect'

export default function FooterInfoComponent(props){

  return (
    <React.Fragment>
        <div className="appWrapper-grid">
          <div className="appWrapper-address clearfix">
            <p className="infofooter-description">If you would like to get in touch with Target Healthcare Limited to discuss opening an account, placing an order, or providing feedback about our services, then you can do so below.</p>
            <div className="info-wrapper">
              <div>
                <h2 className="info-title"><i className="flaticon-placeholder-2 icon"></i>Address</h2>
                <div className="info-container">
                   <div>
                      <h2>Target Healthcare Limited (Glasgow)</h2>
                      <p>8 Redwood Crescent</p>
                      <p>Peel Park</p>
                      <p>East Kilbride</p>
                      <p>Glasgow</p>
                      <p>G74 5PA</p>
                   </div>
                   <div>
                      <h2>Target Healthcare Limited (Luton)</h2>
                      <p>32 Bilton Way</p>
                      <p>Luton</p>
                      <p>Bedfordshire</p>
                      <p>LU1 1UU</p>
                   </div>
                </div>
              </div>
              <div>
               <h2 className="info-title"><i className="flaticon-time icon"></i>Store Hours</h2>
               <div className="info-container">
                 <div>
                  <div className="hours-wrapper">
                      <div><p>Monday</p></div>
                      <div><p className="green">8am – 7pm</p></div>
                  </div>
                  <div className="hours-wrapper">
                      <div><p>Tuesday</p></div>
                      <div><p className="green">8am – 7pm</p></div>
                  </div>
                  <div className="hours-wrapper">
                      <div><p>Wednesday</p></div>
                      <div><p className="green">8am – 7pm</p></div>
                  </div>
                  <div className="hours-wrapper">
                      <div><p>Thursday</p></div>
                      <div><p className="green">8am – 7pm</p></div>
                  </div>
                 </div>
                 <div>
                    <div className="hours-wrapper">
                        <div><p>Friday</p></div>
                        <div><p className="green">8am – 7pm</p></div>
                    </div>
                    <div className="hours-wrapper">
                        <div><p>Saturday</p></div>
                        <div><p className="red">Closed</p></div>
                    </div>
                    <div className="hours-wrapper">
                        <div><p>Sunday</p></div>
                        <div><p className="red">Closed</p></div>
                    </div>
                 </div>
               </div>
              </div>
            </div>
         </div>
     </div>
    </React.Fragment>
  )
}
