import React,{useState,useRef,useEffect} from 'react'
import { Formik, Field } from 'formik'
import axios from 'axios'
import moment from 'moment'
import useOnclickOutside from "react-cool-onclickoutside"
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'

import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import { ValidatePassword } from '../../validations/schemaAccount'



export default function AccountComponent(props){

  /*----------------------------------------
    HANDLE CLICK OUTSIDE ELEMENT
  -----------------------------------------*/

  const handleClickAway = useOnclickOutside(() => CallbackModal({close:true}));
  function CallbackModal(data){
    if(spinner){
      return false;
    }
    props.callback(data);
  }


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [spinner,setspinner] = useState(false);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }


  /*------------------------------------------------------
    NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }


  /*----------------------------------------
    FORM STATE DATA
  -----------------------------------------*/
  const [seen1, setSeen1] = useState(false);
  const [seen2, setSeen2] = useState(false);
  const buttonEl = useRef(null);
  const [buttonclick, setbuttonClick] = useState(0);
  function buttonSubmit(arg){
      setbuttonClick(arg);
      buttonEl.current.click();
  }

  const [state, setstate] = useState({
    account_number:props.data.account_number,
    account_name:props.data.account_name,
    email:props.data.email,
    old_password:'',
    password:'',
  });



  function handleSubmitForm(values, props){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:"Update is processing, please wait..."}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','account');
    formData.append('info',JSON.stringify(values));
    axios.post('/portal/auth/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setspinner(false);

      if(obj=='success'){
        setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}});
        setnotify(true);
        setTimeout(function() {
          WindowReload();
          setnotify(false);
        },2000);
        return false;
      }

      if(obj=='error'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Something Wrong!',text:'Failed to connect through the server.'}});
        setnotify(true);
        setTimeout(function() {setnotify(false);},3000);
        return false;
      }

      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Input Error!',text:'Please correct the required fields.'}});
      setnotify(true);
      setTimeout(function() {setnotify(false);},3000);

      Object.keys(obj).forEach(function(key) {
        props.setErrors(obj);
      });


    })
    .catch(function (error){
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
      setTimeout(function() {setnotify(false);},3000);
    });
  }



  /*------------------------------------------------------
        HANDLE LOGOUT
  -------------------------------------------------------*/
  function Logout(){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:"Logout is processing, please wait..."}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','logout');
    axios.post('/portal/auth/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setspinner(false);

      setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Successfully logout'}});
      setnotify(true);
      setTimeout(function() {
        WindowReload();
        setnotify(false);
      },2000);
      return false;


    })
    .catch(function (error){
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
      setTimeout(function() {setnotify(false);},3000);
    });
  }


  return (
    <React.Fragment>

    <Formik
        initialValues={state}
        validationSchema={ValidatePassword}
        onSubmit={handleSubmitForm}
        render={formProps => {
        const { values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, dirty, touched, setFieldValue } = formProps

        return(
           <Form>
            <div className="modalpanel-wrapper top">
              <div className="modalpanel-container tiny right animate__animated animate__slideInRight animate__faster" ref={handleClickAway}>

              {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
              {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

              {/*---------------------------------------------------------
                 HEADER
                --------------------------------------------------------*/}
                 <div className="modalpanel-header">
                    <div><h2>Account</h2></div>
                    <div onClick={()=>CallbackModal({close:true})}><i className="uil-multiply"></i></div>
                 </div>


                 {/*---------------------------------------------------------
                    BODY
                   --------------------------------------------------------*/}

                 <div className="modalpanel-body">
                    <div className="modalpanel-content">

                        <p style={{'font-weight':'800'}}>Account Number</p>
                        <p>{values.account_number}</p>
                        <br/>

                        <p style={{'font-weight':'800'}}>Account Name</p>
                        <p>{values.account_name}</p>
                        <br/>

                        <p style={{'font-weight':'800'}}>Email</p>
                        <p>{values.email}</p>
                        <br/>


                        <div class="alert-message left info" style={{'margin':'10px 0'}}>
                            <div><i class="info circle icon"></i></div>
                            <div><p>Enter your old password and new password below if you want to change your password.</p></div>
                        </div>

                        <Form.Group widths="equal">
                          <Field name="old_password">
                              {({ field, form }) => (
                              <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                <label>Old Password</label>
                                <Input
                                  type={seen1==true?'text':'password'}
                                  icon={<Icon name={seen1==true?'eye':'eye slash'} link onClick={()=>setSeen1(seen1==true?false:true)}/>}
                                  fluid {...field}
                                  onChange={handleChange}/>
                                { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i className="uil-multiply"></i>{form.errors[field.name]}</Label> }
                              </Form.Field>
                              )}
                          </Field>
                        </Form.Group>

                        <Form.Group widths="equal">
                          <Field name="password">
                              {({ field, form }) => (
                              <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                <label>New Password</label>
                                <Input
                                  type={seen2==true?'text':'password'}
                                  icon={<Icon name={seen2==true?'eye':'eye slash'} link onClick={()=>setSeen2(seen2==true?false:true)}/>}
                                  fluid {...field}
                                  onChange={handleChange}/>
                                { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i className="uil-multiply"></i>{form.errors[field.name]}</Label> }
                              </Form.Field>
                              )}
                          </Field>
                        </Form.Group>

                        <Button disabled={!(isValid&&dirty)} className='GreenButton uppercase strong' fluid size='small' content='Update Password' onClick={() => buttonSubmit(1)}/>

                    </div>
                 </div>


                 {/*---------------------------------------------------------
                    FOOTER
                   --------------------------------------------------------*/}

                 <div className="modalpanel-footer right">
                     <Button fluid className="PrimaryButton uppercase strong" size='small' content='LOGOUT' icon='sign-out' labelPosition='right' onClick={() => Logout()}/>
                     <button type="button" className="display_none" type="button" ref={buttonEl} onClick={handleSubmit}></button>
                 </div>



              </div>{/*.modalpanel-container*/}
            </div>
          </Form>
        )}}/>
    </React.Fragment>
  )
}
