import React,{useState,useEffect} from 'react'
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
import axios from 'axios'

import 'semantic-ui-css/semantic.css'
import 'animate.css'
import './assets/css/unicons.css'
import './assets/css/flaticon.css'
import './assets/css/style.css'

import { RouteURL } from './services/redirect'

/*---------------------------------------
 Root Pages
---------------------------------------*/
import RootComponent from './pages/root'

/*---------------------------------------
 Login and Reset
---------------------------------------*/
import LoginPage from './pages/Login/login'
import ResetLogin from './pages/Login/reset'
import PasswordPage from './pages/Login/password'


/*---------------------------------------
 Page not Found: 404
---------------------------------------*/
import ErrorPage from './pages/ErrorPage/error'

/*---------------------------------------
Backend URL
---------------------------------------*/

/* ---> Live Server --*/
axios.defaults.baseURL = 'https://specials.target-healthcare.co.uk/api'

/* ---> Test Server --*/
//axios.defaults.baseURL = 'https://specials-test.target-healthcare.co.uk/api'


function App() {

  return (
    <React.Fragment>
       <BrowserRouter>
         <Routes>
           <Route path={RouteURL('*')} element={<RootComponent/>} />
           <Route path={RouteURL('login')} element={<LoginPage/>} />
           <Route path={RouteURL('reset')} element={<ResetLogin/>} />
           <Route path={RouteURL('password/:id')} element={<PasswordPage/>} />
           <Route path={RouteURL('404')} element={<ErrorPage/>} />
         </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
