import React,{useState,useRef,useEffect} from 'react'
import { Formik, Field, getIn, FieldArray } from 'formik'
import axios from 'axios'
import { Userpilot } from 'userpilot'

import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

/*----------------------------------------
  Dropzone image
-----------------------------------------*/
import DropzoneUploadPrescription from '../../components/dropzone_Prescription'


import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import ModalConfirmation from '../../services/alertConfirmation'
import { DropdownComponent } from '../../components/options'
import { ValidateForm } from '../../validations/schemaCart'

//Userpilot.initialize('NX-e987cecf');

export default function CartHistoryTestDevComponent(props){

  /*----------------------------------------
    DISABLED & ENABLED SCROLL BODY
  -----------------------------------------*/
  function ClassModalBody(data){
    if(data){
      document.body.classList.add("modal");
    }else{
      document.body.classList.remove("modal");
    }
  }


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(false);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      ClassModalBody(false);
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
    if(data.intent=='confirm'){
      DeleteRecord(data.id);
    }
  }


  /*------------------------------------------------------
    MODAL ALERT CONFIRMATION
    setmodalConfirmContent({intent:'modal',settings:{id:0,centered:false,onclose:false,icon:'success',title:'Thank You!',text:'Your order has been submitted, you will receive an email shortly.'}});
    setmodalConfirm(true);
  -------------------------------------------------------*/
  const [modalConfirm,setmodalConfirm] = useState(true);
  const [modalConfirmContent, setmodalConfirmContent] = useState({});
  function ModalConfirmcallback(data){
    if(data.intent=='close'){
      setmodalConfirm(false);
      WindowReload();
    }
    if(data.intent=='redirect'){
      WindowLocation('/');
    }
  }




    /*----------------------------------------
    LIST CART HISTORY
  -----------------------------------------*/
  /*----------------------------------------
    LIST DATA TABLE
  -----------------------------------------*/
  const buttonEl = useRef(null);
  const [buttonclick, setbuttonClick] = useState(0);
  function buttonSubmit(arg){
      setbuttonClick(arg);
      buttonEl.current.click();
  }
  function handleSubmitForm(values, props){
    if(buttonclick==1){
      props.resetForm();
      TableList(pagenumber,search);
    }else{
      let value={
        product_name:values.product_name,
      }
      TableList(pagenumber,value);
    }
  }
  function ReloadList(){
    let value={
      product_name:'',
    }
    TableList(pagenumber,value);
  }

  const [search, setsearch]  = useState({
      product_name:'',
	});
  const [list, setlist] = useState([]);
	const [pagenumber, setpagenumber] = useState(1);
	const [totaldata, settotaldata] = useState(0);
	const [entry, setentry] = useState(0);
	const [entrytype, setentrytype] = useState('');

	const [paginations, setpaginations] = useState({
			paginations:[{
			listnav:[],
			pageinfo:'',
			limit_page:'',
			total_page:'',
			total_records:'',
			current_page:'',
			startPage:'',
			endPage:'',
			ellipseLeft:'',
			ellipseRight:'',
			}]
	});

	  function pagenumberfunction(page){
				TableList(page,search);
		}
		function listreload(){
				TableList(pagenumber,search);
		}
		function searchProcess(){
			  TableList(pagenumber,search);
		}
		function TableList(page,searchstr){

        setcontentspinner({intent:'spinner',settings:{options:'relative',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
        setspinner(true);

				let formData = new FormData();
				formData.append('type','listCart_history');
				formData.append('search',JSON.stringify(searchstr));
				formData.append('page',page);
				axios.post('/portal/cart/request.php',formData)
				.then(function (response) {
						let obj = response.data;
						setentry(obj.entries.val);
						setentrytype(obj.entries.type);
						setlist(obj.listtable);
						setpaginations(obj.paginations);
						settotaldata(obj.paginations.paginations.total_records);
            setspinner(false);
				})
				.catch(function (error){
          setspinner(false);
				});
	}


  /*------------------------------------------------------
          DELETE FUNCTION
  -------------------------------------------------------*/
  function RemoveAllCartItem(id){
    setconfirmcontent({intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, record will be deleted forever on the server.'}});
    setconfirm(true);
  }


  function DeleteRecord(id){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:""}});
    setspinner(true);
    setconfirm(false);

    let formData = new FormData();
    formData.append('type','delete_cart_history');
    formData.append('id',id);
    axios.post('/portal/cart/request.php',formData)
    .then(function (response) {
        let obj = response.data;

        setspinner(false);

        if(obj=='error_associated'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Delete Denied',text:'The record cannot be deleted because it is associated with another record.'}});
          setnotify(true);
          return false;
        }

        if(obj=='success'){
          setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully deleted.'}});
          setnotify(true);
          TableList(pagenumber,search);
          setTimeout(function() {setnotify(false);},2000);
        }else{
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Delete Failed',text:'Record not successfully deleted.'}});
          setnotify(true);
          setTimeout(function() {setnotify(false);},2000);
        }


    })
    .catch(function (error){
      setspinner(true);
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
      setTimeout(function() {setnotify(false);},3000);
    });
}




  useEffect(()=>{
    TableList(pagenumber,search);
  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {modalConfirm&&<ModalConfirmation data={modalConfirmContent} callback={ModalConfirmcallback}/>}


       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
       {loader&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}


       <div className="appWrapper-data">
          <div className="appWrapper-grid">

         <div className="appWrapper-title flex">
            <div>
              <h2>Shopping Cart History ({list.length} Item)</h2>
              <p>The following product has been added on all customer shopping cart</p>
            </div>
            <div>
            </div>
         </div>

        {/*---------------------------------------------------------
            TABLE LIST SECTION
           --------------------------------------------------------*/}
        {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

        {!spinner&&<React.Fragment>
        <div className="tableWrapper">
         <div className="panel-table custom">
           <div className="panel-table-body">
           <div className="table-responsive overflow">
             <Table celled compact='very'>
               <Table.Header>
                 <Table.Row>
                   <Table.HeaderCell>Product</Table.HeaderCell>
                   <Table.HeaderCell>Code</Table.HeaderCell>
                   <Table.HeaderCell>Price</Table.HeaderCell>
                   <Table.HeaderCell>Quantity</Table.HeaderCell>
                   <Table.HeaderCell>Status</Table.HeaderCell>
                   <Table.HeaderCell></Table.HeaderCell>
                 </Table.Row>
               </Table.Header>

               <Table.Body>
                     {list.length==0&&<Table.Row negative><Table.Cell  colSpan='6' textAlign="center">There is no product added in your cart.</Table.Cell></Table.Row>}
                     {list.map(function(data, key){
                       return <Table.Row key={key} verticalAlign='top'>
                           <Table.Cell>{data.name}</Table.Cell>
                           <Table.Cell>{data.code}</Table.Cell>
                           <Table.Cell>{data.price}</Table.Cell>
                           <Table.Cell>{data.quantity}</Table.Cell>
                           <Table.Cell collapsing>{parse(data.statusHtml)}</Table.Cell>
                           <Table.Cell collapsing>
                              <Button disabled={data.status==1} className='RedButton' size='tiny' content='Remove'  icon='uil-trash-alt icon' onClick={()=>RemoveAllCartItem(data._idcart)}/>
                           </Table.Cell>
                       </Table.Row>
                     })}
                 </Table.Body>
                 {list.length>0&&
                      <Table.Footer fullWidth>
                          <Table.Row>
                          <Table.HeaderCell colSpan='6'>
                              <div className="dash-footer"><Paginations callbackPagination={paginations} callbackPagenumber={pagenumberfunction}/></div>
                          </Table.HeaderCell>
                          </Table.Row>
                      </Table.Footer>
                    }
               </Table>
              </div>{/*.table-responsive*/}
             </div>{/*.panel-table-body*/}
            </div>{/*.panel-table*/}
          </div>


         </React.Fragment>}

        </div>{/*.appWrapper-grid*/}
      </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
