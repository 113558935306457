import React,{useState,useEffect} from 'react'
import { useMediaQuery } from 'react-responsive'
import { Label } from 'semantic-ui-react'
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import { WindowReload, WindowURL, WindowLocation, RouteURL, ActiveMenu } from '../services/redirect'
import SlideImage from '../assets/images/slide_not_available.jpg'

export default function SliderComponent(props){

  const AutoplaySlider = withAutoplay(AwesomeSlider);


  return (
    <React.Fragment>
        <div className="appWrapper-slider">

          {props.data.length>0&&<AutoplaySlider play={true} cancelOnInteraction={false} interval={6000}>
          {props.data.map(function(slide, key){
            return<div key={key} data-src={slide.image} />})}
          </AutoplaySlider>}

          {props.data.length>0&&<div className="slider-wrapper">
            <div className="slider-container">
              <div className="slider-text">
                <h2>TARGET HEALTHCARE LIMITED</h2>
                <p>Generic, branded and unlicensed pharmaceutical supplier</p>
              </div>
            </div>
          </div>}

        </div>
    </React.Fragment>
  )
}
