import React,{useState,useRef,useEffect} from 'react'
import { Formik, Field, getIn, FieldArray } from 'formik'
import axios from 'axios'
import { Userpilot } from 'userpilot'

import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

/*----------------------------------------
  Dropzone image
-----------------------------------------*/
import DropzoneUploadPrescription from '../../components/dropzone_Prescription'


import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import ModalConfirmation from '../../services/alertConfirmation'
import { DropdownComponent } from '../../components/options'
import { ValidateForm } from '../../validations/schemaCart'

//Userpilot.initialize('NX-e987cecf');

export default function CartComponent(props){

  /*----------------------------------------
    DISABLED & ENABLED SCROLL BODY
  -----------------------------------------*/
  function ClassModalBody(data){
    if(data){
      document.body.classList.add("modal");
    }else{
      document.body.classList.remove("modal");
    }
  }


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(false);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      ClassModalBody(false);
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
    if(data.intent=='confirm'){
      ConfirmRemoveCartItem(data.id);
    }
  }


  /*------------------------------------------------------
    MODAL ALERT CONFIRMATION
    setmodalConfirmContent({intent:'modal',settings:{id:0,centered:false,onclose:false,icon:'success',title:'Thank You!',text:'Your order has been submitted, you will receive an email shortly.'}});
    setmodalConfirm(true);
  -------------------------------------------------------*/
  const [modalConfirm,setmodalConfirm] = useState(true);
  const [modalConfirmContent, setmodalConfirmContent] = useState({});
  function ModalConfirmcallback(data){
    if(data.intent=='close'){
      setmodalConfirm(false);
      WindowReload();
    }
    if(data.intent=='redirect'){
      WindowLocation('/');
    }
  }


  /*------------------------------------------------------
       ADD TO CART to SERVER
  -------------------------------------------------------*/
  function SaveCartServer(){

    let carDataStorage = localStorage.getItem('cart') !== null?JSON.parse(localStorage.getItem('cart')):[];

    if(carDataStorage.length==0){
      return false;
    }

    let formData = new FormData();
    formData.append('type','cart');
    formData.append('info',JSON.stringify(carDataStorage));
    axios.post('/portal/cart/request.php',formData)
    .then(function (response) {
        let obj = response.data;

        if(obj=='empty_session'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Session Expired!',text:'Please login to your account.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }
  
        if(obj=='empty_order'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Empty Cart!',text:'There is no product added on your cart.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }

        if(obj=='error'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }

    })
    .catch(function (error){
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
    });
}


  function RemoveCartServer(id){

    var postType = 'removeAllCart';
    if(id>0){
      postType   = 'removeCart';
    }

    let formData = new FormData();
    formData.append('type',postType);
    formData.append('id',id);
    axios.post('/portal/cart/request.php',formData)
    .then(function (response) {
        let obj = response.data;

        if(obj=='empty_session'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Session Expired!',text:'Please login to your account.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }
  
        if(obj=='error'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            setnotify(false);
            WindowReload();
          },2000);
          return false;
        }


    })
    .catch(function (error){
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
    });
  }

 /*------------------------------------------------------
    ADD TO CART to SERVER END---------
  -------------------------------------------------------*/



  const [currency, setCurrency] = useState(localStorage.getItem('currency') !== null?JSON.parse(localStorage.getItem('currency')):{currency:'USD'});
  const [pricing, setPricing] = useState(localStorage.getItem('pricing') !== null?localStorage.getItem('pricing'):2);
  const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: currency.currency,
  });


  /*------------------------------------------------------
          GET CART LOCAL STORAGE
  -------------------------------------------------------*/
  const [cartData, setCartData] = useState(localStorage.getItem('cart') !== null?JSON.parse(localStorage.getItem('cart')):[]);
  const [listItem, setListItem] = useState([]);

  function ItemCart(){
    setcontentspinner({intent:'spinner',settings:{options:'relative',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
    setspinner(true);
    if(cartData){
      setListItem(cartData);
      setTimeout(function() {
        setspinner(false);
      },1000);
    }
  }




  /*------------------------------------------------------
          UPDATE ITEM IN CART
  -------------------------------------------------------*/
  function SuccessUpdateCart(){
    if(cartData.length>0){
      document.getElementById('item-cart').innerHTML=cartData.length;
      setListItem(cartData);
    }

    SaveCartServer();

    setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Quantity successfully updated.'}});
    setnotify(true);
    setTimeout(function() {
      setnotify(false);
    },3000);
  }

  function UpdateItemCart(data){
    if (cartData.length>0) {
      for (let product of cartData){
        if(product.id==data.id){
          product.quantity  = data.quantity
          product.price     = data.price
        }
      }
      localStorage.setItem('cart', JSON.stringify(cartData));
      SuccessUpdateCart();
    }
  }

  function UpdateProductQuantity(data){

    // Check if quantity more than 0
    if(parseInt(data.quantity)<1||data.quantity==''||data.quantity==null){
      setnofitycontent({intent:'error',settings:{onclose:false,reload:false,title:'Error Quantity',text:'Quantity is required. Please enter quantity in the field.'}});
      setnotify(true);
      setTimeout(function() {
        WindowReload();
      },2000);
      return false;
    }


    // Check if product is existing or not
    if (cartData.length>0) {
      var itemCart  = cartData.filter(function(val) { return val.id==data.id});
      if(itemCart.length>0){
        UpdateItemCart(data);
        return false;
      }
    }

  }




  /*------------------------------------------------------
          REMOVE ALL ITEM IN CART
  -------------------------------------------------------*/
  function RemoveAllCartItem(id){

    var text  = "This process is not ireversible. Once you continue the process, all product in the cart will be remove.";
    if(id>0){
      text    = "This process is not ireversible. Once you continue the process, product will be remove in cart.";
    }

    setconfirmcontent({intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Remove Confirmation!',text:text}});
    setconfirm(true);

  }

  function RemoveProductItem(id){
    if (cartData.length>0) {
      var index  = cartData.findIndex(value => value.id === id);
      if(index == -1){
      // if not matched selected index
      } else {
        // is matched, remove...
        cartData.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(cartData));
        setListItem(cartData);
        document.getElementById('item-cart').innerHTML=cartData.length;
        setconfirm(false);
      }
    }
  }

  function ConfirmRemoveCartItem(id){

    RemoveCartServer(id);

    if(id>0){
      RemoveProductItem(id);
      return false;
    }

    localStorage.removeItem('cart');
    document.getElementById('item-cart').innerHTML=0;
    setconfirm(false);
    setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Products successfully removed in cart.'}});
    setnotify(true);
    setListItem([]);
    setTimeout(function() {
      setnotify(false);
    },2000);
  }


  /*------------------------------------------------------
          HANDLE SUBMIT UPLOAD PRESCRIPTION
  -------------------------------------------------------*/
  function HandleUpload(datainfo){
    let formData = new FormData();
    formData.append('type','test_upload');
    formData.append('info',JSON.stringify(datainfo));
    axios.post('/portal/orders/request.php',formData)
    .then(function (response) {
      let obj = response.data;
      console.log(obj);
    })
    .catch(function (error){
    });
  }
  function HandleSubmitPrescription(uploadDropzone){

    return HandleUpload(uploadDropzone);

    var numx = 0;
    for( var i = 0; i < uploadDropzone.length; i++ ){
      let file = uploadDropzone[i];
      if(file.file){

          let datainfo = {
            id_cart:file._idcart,
            id_product:file._id
          }

          let formData = new FormData();
          formData.append('type','prescription_upload');
          formData.append('info',JSON.stringify(datainfo));
          formData.append('file',file.file);
          axios.post('/portal/orders/request.php',formData)
          .then(function (response) {
            let obj = response.data;
            if(obj=='1'){
              numx++;
            }
          })
          .catch(function (error){
          });

        }
      }

      return numx;
  }

  /*------------------------------------------------------
          HANDLE SUBMIT FORM CART
  -------------------------------------------------------*/
  const [isfriday, setisFriday] = useState(false);
	function TodayIsFriday(){
		setisFriday(false);
		var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date();
    var dayName = days[d.getDay()];
		if(dayName=='Friday'){
			setisFriday(true);
		}
	}

  

  const [state, setState] = useState({
    order_placed:'',
    first_name:'',
    last_name:'',
    message:'',
    address:'',
    terms:false,
    new_name:false,
    sat_delivery:false,
    account_type:1,
    address_id:0,
    address_ctr:1,
    prescription_upload:[]
  });

  function handleSubmitForm(values, props){

    if(cartData.length==0){
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Orders Error!',text:'There is no product added on your cart.'}});
      setnotify(true);
      setTimeout(function() {
        WindowReload();
      },2000);
      return false;
    }

    
    var uploadFiltered = [];
    if(values.prescription_upload.length>0){
       uploadFiltered = values.prescription_upload.filter(function (element) {
        return element.file != '';
      });
    }
    
    
    const value = {
      orders:cartData,
      order_placed:values.order_placed,
      first_name:values.first_name,
      last_name:values.last_name,
      address:values.address,
      message:values.message,
      new_name:values.new_name?1:0,
      sat_delivery:values.sat_delivery?1:0,
      prescription_upload:uploadFiltered,
    }

  
    // USER PILOT DATA / TURN OFF
    /*const customerOrders = cartData.map((data) => {
      return {
        product_code: data.code,
        product_name: data.name,
        product_quantity:data.quantity
      };
    })*/
    
    /*Userpilot.track("Orders", customerOrders);
    console.log('successfull');
    return false;*/

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:"Orders is processing, please wait..."}});
    setloader(true);

    //HandleSubmitPrescription(value.prescription_upload);
 
    let formData = new FormData();
    formData.append('type','order');
    formData.append('info',JSON.stringify(value));
    axios.post('/portal/orders/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setloader(false);
    
      if(obj=='empty_session'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Session Expired!',text:'Please login to your account.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }

      if(obj=='empty_upload'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Upload Error!',text:'Something wrong with the upload. Contact the administrator.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }


      if(obj=='empty_order'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Orders Error!',text:'There is no product added on your cart.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }


      if(obj.status=='success'){

         // USER PILOT DATA / TURN OFF
        /*customerOrders.map((data) => (
          Userpilot.track("Orders",{name: data.product_name})
        ))*/

        localStorage.removeItem('cart');
        setmodalConfirmContent({intent:'modal',settings:{id:0,centered:false,onclose:false,icon:'success',title:'Thank You!',text:obj.message,textnote:obj.note}});
        setmodalConfirm(true);
        /*setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Orders successfully process.'}});
        setnotify(true);
        setTimeout(function() {
          localStorage.removeItem('cart');
          WindowReload();
        },2000);*/
        return false;
      }


      if(obj=='error'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }

      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Input Error!',text:'Please correct the required fields.'}});
      setnotify(true);
      setTimeout(function() {
        setnotify(false);
      },2000);

      Object.keys(obj).forEach(function(key) {
        props.setErrors(obj);
      });

    })
    .catch(function (error){
      setloader(false);
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:false,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
      setTimeout(function() {
        setnotify(false);
      },2000);
    });

  }


  function SetDataDefault(){
    setState({
      ...state,
      order_placed:'',
      first_name:'',
      last_name:'',
      message:'',
      address:(props.data.account_type==2&&props.data.address_ctr>1)?'':props.data.address_id,
      terms:false,
      new_name:false,
      sat_delivery:false,
      account_type:props.data.account_type,
      address_id:props.data.address_id,
      address_ctr:props.data.address_ctr,
      prescription_upload:props.data.cart_prescription,
    });
  }


  /*------------------------------------------------------
          DROPDOWN OPTIONS
  -------------------------------------------------------*/
  const [orderPlaceName, setOrderPlaceName] = useState([]);
  const [termsCompliance, setTermCompliance] = useState('');
  const [customerAddress, setcustomerAddress] = useState([]);
  const [noteCustomer, setnoteCustomer] = useState('');
  const [noteAgreement, setnoteAgreement] = useState('');

  const HandleOptions = async () => {
    const source = await DropdownComponent();
    setOrderPlaceName(source.order_placed);
    setTermCompliance(source.terms_compliance);
    setcustomerAddress(source.customer_address);
    setnoteCustomer(source.note_customers);
    setnoteAgreement(source.note_agreement);
  }



  /*---------------------------------------------------------------------------------------------------------------------------
        CUSTOM MESSAGE 
  -----------------------------------------------------------------------------------------------------------------------------*/
  function CustomMessageAlert(page){
    let formData = new FormData();
    formData.append('type','custom_message');
    formData.append('page',page);
    axios.post('/portal/data_message/request.php',formData)
    .then(function (response) {
        let obj     = response.data;
        let message = obj.message;
        if(obj.status=='active'){
          setnofitycontent({intent:'warning_message',settings:{
            onclose:obj.onclose=='Yes'?true:false,
            reload:false,
            title:message.header,
            text:message.body,
            footer:message.footer
          }});
          ClassModalBody(true);
          setnotify(true);
        }
    })
    .catch(function (error){
    });
  }



  useEffect(()=>{
    HandleOptions();
    ItemCart();
    TodayIsFriday();
    SetDataDefault();

    CustomMessageAlert('cart_page');

  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {modalConfirm&&<ModalConfirmation data={modalConfirmContent} callback={ModalConfirmcallback}/>}


       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
       {loader&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}


       <div className="appWrapper-data">
          <div className="appWrapper-grid">

         <div className="appWrapper-title flex">
            <div>
              <h2>Shopping Cart ({cartData.length} Item)</h2>
              <p>The following product has been added on your shopping cart</p>
            </div>
            <div>
               <Button disabled={cartData.length==0} className='RedButton' size='tiny' content='Remove All'  icon='uil-trash-alt icon' onClick={()=>RemoveAllCartItem(0)}/>
               <Button className='GreenButton' size='tiny' content='Continue Shopping'  icon='uil-shopping-cart-alt icon' as="a" href={WindowURL('/products')}/>
            </div>
         </div>

        {/*---------------------------------------------------------
            TABLE LIST SECTION
           --------------------------------------------------------*/}
        {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

        {!spinner&&<React.Fragment>
        <div className="tableWrapper">
         <div className="panel-table custom">
           <div className="panel-table-body">
           <div className="table-responsive overflow">
             <Table celled compact='very'>
               <Table.Header>
                 <Table.Row>
                   <Table.HeaderCell>Product</Table.HeaderCell>
                   <Table.HeaderCell>Code</Table.HeaderCell>
                   <Table.HeaderCell>Price</Table.HeaderCell>
                   <Table.HeaderCell>Quantity</Table.HeaderCell>
                   <Table.HeaderCell>Total</Table.HeaderCell>
                   <Table.HeaderCell></Table.HeaderCell>
                 </Table.Row>
               </Table.Header>

               <Table.Body>
                     {listItem.length==0&&<Table.Row negative><Table.Cell  colSpan='6' textAlign="center">There is no product added in your cart.</Table.Cell></Table.Row>}
                     {listItem.map(function(data, key){
                       return <Table.Row key={key} verticalAlign='top'>
                           <Table.Cell>{data.name}</Table.Cell>
                           <Table.Cell>{data.code}</Table.Cell>
                           <Table.Cell>{pricing==1?currencyFormat.format(data.price):currencyFormat.format(0)}</Table.Cell>
                           <Table.Cell collapsing style={{'width':'100px'}}>
                              <Form>
                                <Form.Group widths="equal">
                                  <Form.Field>
                                    <Cleave
                                        name="quantity"
                                        id="quantity"
                                        options={{numericOnly: true}}
                                        maxLength="5"
                                        value={data.quantity}
                                        onBlur={(e)=>{

                                          if(parseInt(e.target.value)<1||e.target.value==''||e.target.value==null){
                                            setnofitycontent({intent:'error',settings:{onclose:false,reload:false,title:'Error Quantity',text:'Quantity below mimimum, please enter atleast 1 or higher.'}});
                                            setnotify(true);
                                            setTimeout(function() {
                                              WindowReload();
                                            },2000);
                                            return false;
                                          }

                                          if(data.quantity===e.target.value){
                                            return false;
                                          }

                                          UpdateProductQuantity({
                                            id:data.id,
                                            quantity:e.target.value,
                                            price:data.price,
                                            name:data.name,
                                            code:data.code
                                          });


                                        }} style={{'text-align':'center'}}/>
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                           </Table.Cell>
                           <Table.Cell>{pricing==1?currencyFormat.format(data.quantity*data.price):currencyFormat.format(0)}</Table.Cell>
                           <Table.Cell collapsing>
                              <Button className='RedButton' size='tiny' content='Remove'  icon='uil-trash-alt icon' onClick={()=>RemoveAllCartItem(data.id)}/>
                           </Table.Cell>
                       </Table.Row>
                     })}
                 </Table.Body>
               </Table>
              </div>{/*.table-responsive*/}
             </div>{/*.panel-table-body*/}
            </div>{/*.panel-table*/}
          </div>

          <br/>

          <div className="cartFormWrapper">
            <Formik
              initialValues={state}
              validationSchema={ValidateForm}
              onSubmit={handleSubmitForm}
              >
              {({ values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, dirty, touched, setFieldValue, setFieldTouched }) => {

              
                  return(
                  <Form>
                    <div className="panelForm">
                       <div className="panelForm-body">

                          {!values.new_name&&<Form.Group widths='equal'>
                             <Field name="order_placed">
                               {({ field, form }) => (
                                   <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                     <label>Order Placed</label>
                                     <Select selectOnBlur={false}
                                         {...field}
                                         disabled={cartData.length==0}
                                         options={orderPlaceName}
                                         search
                                         selection
                                         onChange={(e, { value }) => setFieldValue(field.name,value)}/>
                                     { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i className="uil-multiply"></i>{form.errors[field.name]}</Label> }
                                   </Form.Field>
                               )}
                             </Field>
                           </Form.Group>}

                           {values.new_name&&<Form.Group widths='equal'>
                              <Field name="first_name">
                              {({ field, form }) => (
                                  <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                    <label>First Name</label>
                                    <Input disabled={cartData.length==0} fluid {...field} onChange={handleChange}/>
                                    { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                  </Form.Field>
                              )}
                              </Field>
                              <Field name="last_name">
                              {({ field, form }) => (
                                  <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                    <label>Last Name</label>
                                    <Input disabled={cartData.length==0} fluid {...field} onChange={handleChange}/>
                                    { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                  </Form.Field>
                              )}
                              </Field>
                          </Form.Group>}

                           <Form.Group widths="equal">
 														<Field name="new_name">
 																{({ field, form }) => (
 																<Form.Field>
 																	<Form.Checkbox
                                    disabled={cartData.length==0}
                                    id="new_name"
                                    label='New order placed name' onChange={(e) => {
                                    if(e.target.checked){
                                      setFieldValue('new_name',true);
                                    }else{
                                      setFieldValue('new_name',false);
                                    }

                                    setFieldValue('first_name','');
      															setFieldValue('last_name','');
      															setFieldValue('order_placed','');

                                  }}/>
 																</Form.Field>
 																)}
 														</Field>
 													</Form.Group>

                           {(values.account_type==2&&values.address_ctr>1)&&<Form.Group widths='equal'>
                             <Field name="address">
                               {({ field, form }) => (
                                   <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                     <label>Address</label>
                                     <Select selectOnBlur={false}
                                         {...field}
                                         disabled={cartData.length==0}
                                         options={customerAddress}
                                         search
                                         selection
                                         onChange={(e, { value }) => setFieldValue(field.name,value)}/>
                                     { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i className="uil-multiply"></i>{form.errors[field.name]}</Label> }
                                   </Form.Field>
                               )}
                             </Field>
                          </Form.Group>}


                          {isfriday&&<Form.Group inline>
	                          <Form.Checkbox disabled={cartData.length==0} name="sat_delivery" id="sat_delivery" label='Saturday Delivery Required' onChange={(e) => {
                              setFieldValue('sat_delivery',e.target.checked)
                            }}/>
	                        </Form.Group>}

                          <Form.Group widths='equal'>
                             <Field name="message">
                             {({ field, form }) => (
                                 <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                   <label>Message</label>
                                   <TextArea disabled={cartData.length==0} fluid {...field} onChange={handleChange} rows="3"/>
                                   { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                 </Form.Field>
                             )}
                             </Field>
                          </Form.Group>
                       </div>



                       {values.prescription_upload.length>0&&<div className="panelForm-footer">
                          <FieldArray
                            name="prescription_upload"
                            render={arrayHelpers => (

                              <React.Fragment>
  
                                   {values.prescription_upload.map(function(data, index){
                                   
                                    function SetFileImage(file){

                                      setFieldValue(`prescription_upload.${index}.file`,file);
                                      if(file.status=='added'){
                                        setFieldValue(`prescription_upload.${index}._file`,file.name);
                                      }else{
                                        setFieldValue(`prescription_upload.${index}._file`,'');
                                      }
                                    }

                                    function SetErrorUploadFile(_error){
                                      if(_error){
                                        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error File',text:_error}});
                                        setnotify(true);
                                      }
                                    }

                                    return <div key={index}> 
                                        <Form.Group widths="equal">
                                          <Field>
                                              {({ field, form }) => (
                                              <Form.Field className={(() => {return getIn(form.errors, `prescription_upload.${index}._file`) && getIn(form.touched, `prescription_upload.${index}._file`) ? 'error' : "";})()}>
                                                <label style={{'font-weight':'700','color':'rgba(17, 17, 17,1)'}}>{data._name}</label>
                                                <label>Image for the prescription
                                                  {data._type==1&&<span style={{'color':'#9F3A38','fontStyle':'italic', 'padding-left':'5px'}}>(required)</span>}
                                                  {data._type==2&&<span style={{'fontStyle':'italic', 'padding-left':'5px'}}>(optional)</span>}
                                                </label>
                                                <DropzoneUploadPrescription data={SetFileImage} error_data={SetErrorUploadFile}/>
                                                { getIn(form.touched, `prescription_upload.${index}._file`) && getIn(form.errors, `prescription_upload.${index}._file`) && <Label className="ui above prompt label errorlabel"><i className="uil-multiply"></i>{getIn(form.errors, `prescription_upload.${index}._file`)}</Label> }
                                              </Form.Field>
                                              )}
                                          </Field>
                                        </Form.Group>
                                    </div>
                            })}

                        </React.Fragment>
                      )}/></div>}


                       <div className="panelForm-footer">

                           <div class="alert-message left error" style={{'margin':'10px 0','border':'1px solid #e0b4b4'}} disabled={cartData.length==0}>
                               <div><i class="info circle icon"></i></div>
                               <div><p>{termsCompliance}</p></div>
                           </div>

                           {noteAgreement!=''&&<div class="alert-message left error" style={{'margin':'10px 0','border':'1px solid #e0b4b4'}} disabled={cartData.length==0}>
                              <div><i class="info circle icon"></i></div>
                              <div><p style={{'font-weigth':'bold','color':'rgba(0, 0, 0)'}}>{noteAgreement}</p></div>
                          </div>}


                           <Form.Group widths="equal">
 														<Field name="terms">
 																{({ field, form }) => (
 																<Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
 																	<Form.Checkbox disabled={cartData.length==0} id="terms" label='Tick the box if you agree and understand' onChange={(e) => {
                                    if(e.target.checked){
                                      setFieldValue('terms',true);
                                    }else{
                                      setFieldValue('terms',false);
                                    }
                                  }}/>
 																</Form.Field>
 																)}
 														</Field>
 													</Form.Group>
                       </div>
                    </div>{/*.panelForm*/}

                    {noteCustomer!=''&&<div class="alert-message left info" style={{'margin':'10px 0','border':'1px solid #abd6fb'}} disabled={cartData.length==0}>
                        <div><i class="info circle icon"></i></div>
                        <div><p style={{'font-weigth':'bold','color':'rgba(0, 0, 0)'}}>{noteCustomer}</p></div>
                    </div>}

                    
                    <div className="buttonWrapper">
                        <Button disabled={cartData.length==0||loader} className='PrimaryButton uppercase strong' size='large' content='Confirm Order' onClick={handleSubmit}/>
                    </div>
                </Form>
               )}}
            </Formik>
            </div>



         </React.Fragment>}

        </div>{/*.appWrapper-grid*/}
      </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
