import * as Yup from 'yup'

export function ValidateUserForms(values) {
    return Yup.object().shape({
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        email: Yup.string().email('Please enter a valid email').required('This field is required'),
        password: Yup.string().required('This field is required').min(6, 'Password should be 6 chars minimum.'),
    });
}
export function ValidateUserUpdateForms(values) {
    return Yup.object().shape({
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        email: Yup.string().email('Please enter a valid email').required('This field is required'),
        password: Yup.string().when("is_password", {
            is: (is_password) => is_password==1,
            then: Yup.string().required('This field is required').min(6, 'Password should be 6 chars minimum.'),
        }),
    });
}


export function ValidateLogin(values) {
    return Yup.object().shape({
        email: Yup.string().required('This field is required'),
        password: Yup.string().required('This field is required'),
    });
}


export function ValidateReset(values) {
    return Yup.object().shape({
        email: Yup.string().email('Please enter a valid email').required('This field is required'),
    });
}

export function ValidateNewPassword(values) {
    return Yup.object().shape({
        password: Yup.string().required('This field is required').min(6, 'Password should be 6 chars minimum.'),
    });
}

export function ValidateEmail(values) {
    return Yup.object().shape({
        email: Yup.string().email('Please enter a valid email.').required('Please enter a valid email.'),
    });
}
