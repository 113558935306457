import React,{useState,useRef,useEffect} from 'react'
import { WindowReload, WindowURL, WindowLocation } from '../../services/redirect'
export default function Redirect404(props){
  useState(()=>{
      WindowLocation('/404');
  },[]);
  return (
    <React.Fragment>
        <h1>Page Not Found</h1>
    </React.Fragment>
  )
}
