import * as Yup from 'yup'
export function ValidateForm(values) {
    return Yup.object().shape({
        order_placed: Yup.string().when("new_name", {
            is: false,
            then: Yup.string().required('This field is required'),
        }),
        first_name: Yup.string().when("new_name", {
            is: true,
            then: Yup.string().required('This field is required'),
        }),
        last_name: Yup.string().when("new_name", {
            is: true,
            then: Yup.string().required('This field is required'),
        }),
        address:Yup.string().required('This field is required'),
        terms: Yup.boolean().oneOf([true], 'You must accept the confirmation'),
        prescription_upload: Yup.array().of(
            Yup.object().shape({
                _file: Yup.string().when("_type", {
                    is: 1,
                    then: Yup.string().required('This field is required'),
                })
            })
        )
        /*prescription_upload: Yup.array().of(
            Yup.object().shape({
              _file: Yup.string().required('This field is required'),
            })
        )*/
    });
}
