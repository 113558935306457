import React, { Component } from 'react'
import { Button, Label, Dimmer, Loader, Icon, Modal, Popup } from 'semantic-ui-react'
import Success from '../assets/images/alert/success.png'
import Error from '../assets/images/alert/error.png'
import Warning from '../assets/images/alert/warning.png'
import Info from '../assets/images/alert/info.png'
export default function ModalConfirmation(props) {

    function PassCallback(data){
      props.callback(data);
    }

    return (
     <React.Fragment>

        {/*-----------------------------------------------------------
          Modal Confirmations
          ----------------------------------------------------------*/}
         {props.data.intent=='modal'&&<Modal className="animate__animated animate__slideInDown animate__faster" size={'mini'} open={'open'} centered={props.data.settings.centered} onClose={()=>PassCallback({intent:props.data.settings.onclose?'close':''})}>
           <Modal.Content className="alert-content-wrapper" scrolling>
             <img src={props.data.settings.icon=='success'?Success:props.data.settings.icon=='error'?Info:Error}/>
             <h1>{props.data.settings.title}</h1>
             <p>{props.data.settings.text}</p>
             <p style={{'margin-top':'10px'}}>{props.data.settings.textnote}</p>
           </Modal.Content>
           <Modal.Actions className="alert-footer-wrapper">
              <Button className="" size='small' icon='close' content='Close' onClick={()=>PassCallback({intent:'close'})}/>
              <Button className="GreenButton" size='small' icon='right arrow' labelPosition='right' content='To Product Page' onClick={()=>PassCallback({intent:'redirect'})}/>
           </Modal.Actions>
         </Modal>}

        
      </React.Fragment>
    );

}
