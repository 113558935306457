import React,{useState,useRef,useEffect} from 'react'
import _ from 'lodash'
import { Formik, Field, FieldArray } from 'formik'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment, Search } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import { DropdownComponent } from '../../components/options'

export default function ProductsComponent(props){
  

   /*----------------------------------------
    DISABLED & ENABLED SCROLL BODY
  -----------------------------------------*/
  function ClassModalBody(data){
    if(data){
      document.body.classList.add("modal");
    }else{
      document.body.classList.remove("modal");
    }
  }


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(true);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      ClassModalBody(false);
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
  }


  /*----------------------------------------
    LIST DATA TABLE
  -----------------------------------------*/
  const buttonEl = useRef(null);
  const [buttonclick, setbuttonClick] = useState(0);
  function buttonSubmit(arg){
      setbuttonClick(arg);
      buttonEl.current.click();
  }
  function handleSubmitForm(values, props){
    if(buttonclick==1){
      props.resetForm();
      TableList(pagenumber,search);
    }else{
      let value={
        product__id:values.product__id,
        name:values.name,
        code:values.code,
        status:values.status,
      }
      TableList(pagenumber,values);
    }
  }
  function ReloadList(){
    let value={
      product__id:'',
      name:'',
      code:'',
      status:'',
    }
    TableList(pagenumber,value);
  }

  const [search, setsearch]  = useState({
      product__id:'',
      name:'',
      code:'',
      status:'',
	});
  const [list, setlist] = useState([]);
	const [pagenumber, setpagenumber] = useState(1);
	const [totaldata, settotaldata] = useState(0);
	const [entry, setentry] = useState(0);
	const [entrytype, setentrytype] = useState('');

	const [paginations, setpaginations] = useState({
			paginations:[{
			listnav:[],
			pageinfo:'',
			limit_page:'',
			total_page:'',
			total_records:'',
			current_page:'',
			startPage:'',
			endPage:'',
			ellipseLeft:'',
			ellipseRight:'',
			}]
	});

	  function pagenumberfunction(page){
				TableList(page,search);
		}
		function listreload(){
				TableList(pagenumber,search);
		}
		function searchProcess(){
			  TableList(pagenumber,search);
		}
		function TableList(page,searchstr){

        setcontentspinner({intent:'spinner',settings:{options:'relative',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
        setspinner(true);

				let formData = new FormData();
				formData.append('type','list');
				formData.append('search',JSON.stringify(searchstr));
				formData.append('page',page);
				axios.post('/portal/products/request.php',formData)
				.then(function (response) {
						let obj = response.data;
						setentry(obj.entries.val);
						setentrytype(obj.entries.type);
						setlist(obj.listtable);
						setpaginations(obj.paginations);
						settotaldata(obj.paginations.paginations.total_records);
            setspinner(false);
				})
				.catch(function (error){
          setspinner(false);
				});
	}


  /*------------------------------------------------------
        SLIDER
  -------------------------------------------------------*/
  const [slide, setSlider] = useState([]);
  function Slider(){
      setcontentspinner({intent:'spinner',settings:{options:'relative',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
      setloader(true);

      let formData = new FormData();
      formData.append('type','slider');
      axios.post('/portal/slider/request.php',formData)
      .then(function (response) {
          let obj = response.data;
          setSlider(obj);
          setloader(false);
      })
      .catch(function (error){
        setloader(false);
      });
}

  /*------------------------------------------------------
          DROPDOWN OPTIONS
  -------------------------------------------------------*/
  const [statusOptions, setStatusDropdown] = useState([]);
  const HandleOptions = async () => {
    const source = await DropdownComponent();
    setStatusDropdown(source.available_status);
  }


  /*------------------------------------------------------
        SEARCH SCRIPT
  -------------------------------------------------------*/
  const [searchLoading, setsearchLoading] = useState(false);
  const [searchState, setsearchState] = useState('');
  const [searchResults, setsearchResults] = useState([]);



  /*------------------------------------------------------
       ADD TO CART to SERVER
  -------------------------------------------------------*/
  function SaveCartServer(){

    let carDataStorage = localStorage.getItem('cart') !== null?JSON.parse(localStorage.getItem('cart')):[];

    if(carDataStorage.length==0){
      return false;
    }

    let formData = new FormData();
    formData.append('type','cart');
    formData.append('info',JSON.stringify(carDataStorage));
    axios.post('/portal/cart/request.php',formData)
    .then(function (response) {
        let obj = response.data;

        if(obj=='empty_session'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Session Expired!',text:'Please login to your account.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }
  
        if(obj=='empty_order'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Empty Cart!',text:'There is no product added on your cart.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }

        if(obj=='error'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }

    })
    .catch(function (error){
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
    });
}

 /*------------------------------------------------------
    ADD TO CART to SERVER END---------
  -------------------------------------------------------*/


  /*------------------------------------------------------
       ADD TO CART script
  -------------------------------------------------------*/

  const [cartData, setcartData] = useState({
    price_variation:[]
  });
  function handleSubmitCart(values, props){
  }


  function SaveCartLocalStorage(data){
    var a = [];
    // Parse the serialized data back into an aray of objects
    a = JSON.parse(localStorage.getItem('cart')) || [];
    // Push the new data (whether it be an object or anything else) onto the array
    a.push(data);
    // Alert the array value
    // Re-serialize the array back into a string and store it in localStorage
    localStorage.setItem('cart', JSON.stringify(a));
  }

  function SuccessAddCart(){
    const cartData = JSON.parse(localStorage.getItem('cart'));
    if(cartData.length>0){
      document.getElementById('item-cart').innerHTML=cartData.length;
    }

    SaveCartServer();

    setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Successfully added to your cart.'}});
    setnotify(true);
    setTimeout(function() {
      setnotify(false);
    },3000);
  }


  function RemoveOldItem(id){
    if (localStorage.getItem('cart') !== null) {
      var cartData  = JSON.parse(localStorage.getItem('cart'));
      var index  = cartData.findIndex(value => value.id === id);
      if(index == -1){
      // if not matched selected index
      } else {
        // is matched, remove...
        cartData.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(cartData));
      }
    }
  }


  function UpdateItemCart(data){

    if (localStorage.getItem('cart') !== null) {

      const cartData = JSON.parse(localStorage.getItem('cart'));
      for (let product of cartData){
        if(product.id==data.id){
          product.quantity  = data.quantity
          product.price     = data.price
        }
      }

      localStorage.setItem('cart', JSON.stringify(cartData));
      SuccessAddCart();

    }
  }



  function AddToCart(data){


    // Check if quantity more than 0
    if(parseInt(data.quantity)<1||data.quantity==0||data.quantity==''||data.quantity==null){
      return false;
    }


    // Check if product is existing or not
    if (localStorage.getItem('cart') !== null) {
      const localCart = JSON.parse(localStorage.getItem('cart'));
      if(localCart.length>0){
        var itemCart  = localCart.filter(function(val) { return val.id==data.id});
        if(itemCart.length>0){
          UpdateItemCart(data);
          return false;
          //RemoveOldItem(data.id);
        }
      }
    }


    const values = {
      id:data.id,
      quantity:data.quantity,
      price:data.price,
      code:data.code,
      name:data.name,
    }

    SaveCartLocalStorage(values);
    SuccessAddCart();
  }



  /*---------------------------------------------------------------------------------------------------------------------------
        CUSTOM MESSAGE 
  -----------------------------------------------------------------------------------------------------------------------------*/
  function CustomMessageAlert(page){
    let formData = new FormData();
    formData.append('type','custom_message');
    formData.append('page',page);
    axios.post('/portal/data_message/request.php',formData)
    .then(function (response) {
        let obj     = response.data;
        let message = obj.message;
        if(obj.status=='active'){
          setnofitycontent({intent:'warning_message',settings:{
            onclose:obj.onclose=='Yes'?true:false,
            reload:false,
            title:message.header,
            text:message.body,
            footer:message.footer
          }});
          ClassModalBody(true);
          setnotify(true);
        }
    })
    .catch(function (error){
    });
  }



  useEffect(()=>{
    Slider();
    TableList(pagenumber,search);
    HandleOptions();

    CustomMessageAlert('product_page');

  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}

       <div className="appWrapper-data">
          <div className="appWrapper-grid">


          <div className="appWrapper-title">
            <div>
              <h2>Products</h2>
              <p>The following product has been added to your products page</p>
            </div>
          </div>

       {/*---------------------------------------------------------
          SEARCH SECTION
         --------------------------------------------------------*/}
         <Formik
            initialValues={search}
            onSubmit={handleSubmitForm}
            >
         {({ values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, dirty, touched, setFieldValue, setFieldTouched }) => {

          const handleSearchResultSelected = (e, { result }) => {
            setsearchState(result.title);
            setFieldValue('name', result.tile);
            setFieldValue('product__id', result.id);
            buttonSubmit(2);
          }

          const handleSearchChange = (e, { value }) => {

            setsearchState(value);
            setsearchLoading(true);

            setTimeout(() => {
            
              if (value.length < 1){
                setsearchState('');
                setsearchLoading(false);
                setsearchResults([]);
                setFieldValue('product__id', 0);
                setTimeout(() => {
                buttonSubmit(2);
                });
                return false;
              }

              let formData = new FormData();
              formData.append('type','search_api');
              formData.append('search_string',value);
              axios.post('/include/searchapi.php',formData)
              .then(function (response) {


                let obj = response.data;
                setsearchResults(obj);

                //const re = new RegExp(_.escapeRegExp(value), 'i');
                //const isMatch = (result) => re.test(result.title)
                //setstateResults(_.filter(obj, isMatch));
                setsearchLoading(false);

              })
              .catch(function (error){
                setsearchLoading(false);
              });


            }, 300)
          }


         return(<React.Fragment>
           <div className="search-wrapper">
              <div className="search-body">
                <Form>
                  <Form.Group widths="equal">


                      {/*<Field name="name">
                          {({ field, form }) => (
                          <Form.Field>
                            <label>Name</label>
                            <Search
                                fluid
                                input={{ icon: 'search', iconPosition: 'right' }}
                                loading={searchLoading}
                                onBlur={(e ,{ value })=>{
                                  if(values.product__id==0){
                                    setsearchState('');
                                  }
                                }}
                                onResultSelect={handleSearchResultSelected}
                                onSearchChange={_.debounce(handleSearchChange, 500, {
                                    leading: true,
                                })}
                                results={searchResults}
                                value={searchState}
                            />
                          </Form.Field>
                          )}
                        </Field>*/}
                        <Field name="name">
                            {({ field, form }) => (
                            <Form.Field>
                              <label>Name</label>
                              <Input fluid {...field} onChange={(e, { value }) => {
                                   setFieldValue(field.name,value)
                                   TableList(pagenumber,{
                                    product__id:0,
                                    name:value,
                                    code:values.code,
                                    status:values.status,
                                   });
                              }}/>
                            </Form.Field>
                            )}
                        </Field>


                        <Field name="code">
                            {({ field, form }) => (
                            <Form.Field>
                              <label>Code</label>
                              <Input fluid {...field} onChange={handleChange}/>
                            </Form.Field>
                            )}
                          </Field>
                          <Field name="status">
                            {({ field, form }) => (
                            <Form.Field>
                              <label>Status</label>
                              <Select
                                  selectOnBlur={false}
                                  {...field}
                                  options={statusOptions}
                                  onChange={(e, { value }) => setFieldValue(field.name,value)}/>

                            </Form.Field>
                            )}
                          </Field>

                    </Form.Group>
                 </Form>
              </div>
              <div className="search-footer">
              <Button type="button" icon='sync alternate' size='tiny' className="LightGrayButton" content='Reset' onClick={() => {
                  setsearchResults([]);
                  setsearchState('');
                  buttonSubmit(1);
                  TableList(pagenumber,{
                    product__id:0,
                    name:'',
                    code:'',
                    status:'',
                  });
                }}/>
                <Button type="button" icon='search' size='tiny' className="BasicButton" content='Search' onClick={() => buttonSubmit(2)}/>
                <button type="button" style={{'display':'none'}} ref={buttonEl} onClick={handleSubmit}></button>
              </div>
           </div>
        </React.Fragment>
          )}}
         </Formik>





      {/*---------------------------------------------------------
          TABLE LIST SECTION
         --------------------------------------------------------*/}
      {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

      <Formik
        initialValues={cartData}
        onSubmit={handleSubmitCart}
        render={formProps => {
        const { values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, touched, setFieldValue } = formProps
        return(<React.Fragment>

        {!spinner&&<React.Fragment>
          <FieldArray
             name="product_array"
             render={arrayHelpers => (
              <div className="tableWrapper">
               <div className="panel-table custom">

                 <div className="panel-table-body">
                 <div className="table-responsive overflow">
                   <Table celled selectable compact='very'>
                     <Table.Header>
                       <Table.Row>
                         <Table.HeaderCell>Name</Table.HeaderCell>
                         <Table.HeaderCell>Code</Table.HeaderCell>
                         <Table.HeaderCell>Price</Table.HeaderCell>
                         <Table.HeaderCell>Status</Table.HeaderCell>
                         <Table.HeaderCell>Quantity</Table.HeaderCell>
                         <Table.HeaderCell></Table.HeaderCell>
                       </Table.Row>
                     </Table.Header>

                     <Table.Body>
                           {list.length==0&&<Table.Row negative><Table.Cell  colSpan='6' textAlign="center">No record found</Table.Cell></Table.Row>}
                           {list.map(function(data, key){
                             return <Table.Row key={key} verticalAlign='top' negative={data.status==2}>
                                 <Table.Cell>{data.name}</Table.Cell>
                                 <Table.Cell>{data.code}</Table.Cell>
                                 <Table.Cell>{data.price}</Table.Cell>
                                 <Table.Cell collapsing>{parse(data.status_html)}</Table.Cell>
                                 <Table.Cell collapsing style={{'width':'100px'}}>
                                    <Form>
                                      <Form.Group widths="equal">
                                         <Field name={`product_array[${key}].quantity`}>
                                             {({ field, form }) => (
                                             <Form.Field>
                                               <Cleave
                                                    disabled={data.status==2}
                                                    {...field}
                                                     onChange={event => {
                                                       setFieldValue(`product_array[${key}].id`,data.id);
                                                       setFieldValue(`product_array[${key}].quantity`,event.target.value);
                                                       setFieldValue(`product_array[${key}].price`,data.amount);
                                                       setFieldValue(`product_array[${key}].name`,data.name);
                                                       setFieldValue(`product_array[${key}].code`,data.code);
                                                   }} options={{numericOnly: true}}/>
                                             </Form.Field>
                                             )}
                                         </Field>
                                      </Form.Group>
                                    </Form>
                                 </Table.Cell>
                                 <Table.Cell collapsing>
                                    <Button disabled={data.status==2} className='GreenButton' size='tiny' content='Add to Cart'  icon='uil-shopping-cart-alt icon' onClick={()=>{

                                        // Add array empty
                                        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Quantity',text:'Quantity is empty. Please enter quantity in the field.'}});
                                        setnotify(true);


                                        // Check if quantity is empty or not
                                        var id         = values.product_array[key].id;
                                        var quantity   = values.product_array[key].quantity;
                                        var price      = values.product_array[key].price;
                                        var code       = values.product_array[key].code;
                                        var name       = values.product_array[key].name;


                                        if(quantity==''||quantity==null){
                                          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Quantity',text:'Quantity is empty. Please enter quantity in the field.'}});
                                          setnotify(true);
                                          return false;
                                        }

                                        if(parseInt(quantity)<1||quantity==0){
                                          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Quantity',text:'Quantity is 0. Please enter quantity greater than 0.'}});
                                          setnotify(true);
                                          return false;
                                        }

                                        AddToCart({
                                          id:id,
                                          quantity:quantity,
                                          price:price,
                                          code:code,
                                          name:name,
                                        });


                                    }}/>
                                 </Table.Cell>
                             </Table.Row>
                           })}
                       </Table.Body>
                       {list.length>0&&
                         <Table.Footer fullWidth>
                             <Table.Row>
                             <Table.HeaderCell colSpan='6'>
                                 <div className="dash-footer"><Paginations callbackPagination={paginations} callbackPagenumber={pagenumberfunction}/></div>
                             </Table.HeaderCell>
                             </Table.Row>
                         </Table.Footer>
                       }
                   </Table>
                  </div>{/*.table-responsive*/}
                 </div>{/*.panel-table-body*/}
                </div>{/*.panel-table*/}
              </div>
                )}/>
            </React.Fragment>}
         </React.Fragment>)}}/>


      </div>{/*.appWrapper-grid*/}
    </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
