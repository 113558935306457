import * as Yup from 'yup'
export function ValidateForm(values) {
    return Yup.object().shape({
        name: Yup.string().required('This field is required'),
        strength: Yup.string().required('This field is required'),
        pharma_form: Yup.string().required('This field is required'),
        pack_size: Yup.string().required('This field is required'),
        no_packs: Yup.string().required('This field is required'),
    });
}
