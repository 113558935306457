import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import moment from 'moment'
import { Icon, Label, Popup, Modal, Button, Form, Input } from 'semantic-ui-react'
import axios from 'axios'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'

import { ValidateNewPassword } from '../../validations/validate'
import UserImg from '../../assets/images/user.png'
import LogoImg from '../../assets/images/logo.png'
export default function PasswordPage(props){

  const params = useParams();

  function ClassBody(){
    document.body.classList.add("login");
  }

  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(true);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
  }


  const [seen,setseen] = useState(false);
  const [state, setState] = useState({
    password:'',
    id:params.id
  });


  function handleSubmitForm(values, props){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:"Changing password is in progress..."}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','authPassword');
    formData.append('info',JSON.stringify(values));
    axios.post('/portal/auth/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setspinner(false);

      if(obj=='success'){
        setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Successfully Changed!',text:'You can login now with the new password.'}});
        setnotify(true);
        setTimeout(function() {
          WindowLocation('/login');
          setnotify(false);
        },2000);
        return false;
      }


      if(obj=='error'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Something Wrong!',text:'Failed to connect through the server. Contact the admin support.'}});
        setnotify(true);
        setTimeout(function() {setnotify(false);},3000);
        return false;
      }

      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Input Error!',text:'Please correct the required fields.'}});
      setnotify(true);
      setTimeout(function() {setnotify(false);},3000);

      Object.keys(obj).forEach(function(key) {
        props.setErrors(obj);
      });


    })
    .catch(function (error){
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server. Contact the admin support.'}});
      setnotify(true);
      setTimeout(function() {setnotify(false);},3000);
    });
  }


  function CheckAuthReset(id){

      setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
      setspinner(true);

      let formData = new FormData();
      formData.append('type','authReset');
      formData.append('id',id);
      axios.post('/portal/auth/request.php',formData)
      .then(function (response) {
          let obj = response.data;
          setspinner(false);
          if(obj==0){

            setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Invalid Reset!',text:'Reset request is not valid or already expired.'}});
            setnotify(true);
            setTimeout(function() {
              WindowLocation('/login');
            },3000);

            return false;
          }
      })
      .catch(function (error){
        setspinner(false);
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server. Contact the admin support.'}});
        setnotify(true);
        setTimeout(function() {setnotify(false);},3000);
      });
  }



  function AuthUser(){

      setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
      setspinner(true);

      let formData = new FormData();
      formData.append('type','auth');
      axios.post('/portal/auth/request.php',formData)
      .then(function (response) {
          let obj = response.data;
          setspinner(false);
          if(obj!=0){
            WindowLocation('/');
            return false;
          }
      })
      .catch(function (error){
        setspinner(false);
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server. Contact the admin support.'}});
        setnotify(true);
        setTimeout(function() {setnotify(false);},3000);
      });
  }



  useState(()=>{
     ClassBody();
     AuthUser();
     CheckAuthReset(params.id);
  },[]);


  return (
    <React.Fragment>

    {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
    {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

    <Formik
        initialValues={state}
        validationSchema={ValidateNewPassword}
        onSubmit={handleSubmitForm}
        render={formProps => {
        const { values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, touched, setFieldValue } = formProps
        return(
        <Form>

            <div className="login-wrapper">
              <div className="login-container">

                <div className="login-logo-wrapper">
                  <img src={LogoImg} alt="Logo"/>
                </div>

                <div className="login-panel">
                    <div className="login-header">
                       <div><img src={UserImg}/></div>
                       <div>
                           <h1>Customer Password</h1>
                           <p>Enter your new password</p>
                       </div>
                    </div>
                    <div className="login-body">
                       <Form.Group widths='equal'>
                         <Field name="password">
                         {({ field, form }) => (
                             <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                               <label>New Password</label>
                               <Input
                                 type={seen==true?'text':'password'}
                                 icon={<Icon name={seen==true?'eye':'eye slash'} link onClick={()=>setseen(seen==true?false:true)}/>}
                                 fluid {...field}
                                 onChange={handleChange}/>
                               { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                             </Form.Field>
                         )}
                         </Field>
                       </Form.Group>
                    </div>
                    <div className="login-footer">
                    <div><Button disabled={spinner} fluid className='DarkButton uppercase strong' content='SUBMIT PASSWORD'  onClick={handleSubmit} /></div>
                    <div><Button as="a" disabled={spinner} fluid className='PrimaryButton uppercase strong' content='Customer Login'  icon='uil-user icon' href={WindowURL('/login')} /></div>
                    </div>
                 </div>

                 <div className="copyright-wrapper">
                    <p>© Copyright {moment(new Date()).year()}. All rights reserved.</p>
                 </div>
                </div>


            </div>

        </Form>
      )}}/>

    </React.Fragment>
  )
}
