import React from 'react'
import { Icon, Label, Popup, Modal, Button, Form, Input } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'
export default function ErrorPage(props){
  return (
    <React.Fragment>
        <div className="Notfound-wrapper">
          <div className="Notfound-container">
             <h1>404</h1>
             <h2>Page not found</h2>
             <p>We're sorry, the page you requested could not be found</p>
             <p>Please go back to the homepage</p>
             <br/>
             <Button as="a" href={WindowURL('/')} className='PrimaryButton uppercase strong' content='GO HOMEPAGE'/>
          </div>
        </div>
    </React.Fragment>
  )
}
