import React, { Component } from 'react'
import { Button, Label, Dimmer, Loader, Icon, Modal, Popup } from 'semantic-ui-react'

export default function AlertMessage(props) {

   function PassCallback(data){
     props.callback(data);
   }


    return (
     <React.Fragment>

         {/*-----------------------------------------------------------
           NOTIFY INFO
           ----------------------------------------------------------*/}
          {props.data.intent=='info'&&<div className="floating-alert-wrapper" onClick={()=>PassCallback({intent:props.data.settings.onclose?'close':'',reload:props.data.settings.reload})}>
            <div className="floating-alert info animate__animated animate__slideInDown animate__faster">
              <div><i className="info circle icon"></i></div>
              <div>
                <h1>{props.data.settings.title}</h1>
                <p>{props.data.settings.text}</p>
              </div>
            </div>
          </div>}



         {/*-----------------------------------------------------------
           NOTIFY SUCCESS
           ----------------------------------------------------------*/}
          {props.data.intent=='success'&&<div className="floating-alert-wrapper" onClick={()=>PassCallback({intent:props.data.settings.onclose?'close':'',reload:props.data.settings.reload})}>
            <div className="floating-alert success animate__animated animate__slideInDown animate__faster">
              <div><i className="check circle icon"></i></div>
              <div>
                <h1>{props.data.settings.title}</h1>
                <p>{props.data.settings.text}</p>
              </div>
            </div>
          </div>}


          {/*-----------------------------------------------------------
            NOTIFY WARNING
            ----------------------------------------------------------*/}
           {props.data.intent=='warning'&&<div className="floating-alert-wrapper" onClick={()=>PassCallback({intent:props.data.settings.onclose?'close':'',reload:props.data.settings.reload})}>
             <div className="floating-alert warning animate__animated animate__slideInDown animate__faster">
               <div><i className="warning sign icon"></i></div>
               <div>
                 <h1>{props.data.settings.title}</h1>
                 <p>{props.data.settings.text}</p>
               </div>
             </div>
           </div>}

           {/*-----------------------------------------------------------
             NOTIFY ERROR
             ----------------------------------------------------------*/}
            {props.data.intent=='error'&&<div className="floating-alert-wrapper" onClick={()=>PassCallback({intent:props.data.settings.onclose?'close':'',reload:props.data.settings.reload})}>
              <div className="floating-alert error animate__animated animate__slideInDown animate__faster">
                <div><i className="times circle icon"></i></div>
                <div>
                  <h1>{props.data.settings.title}</h1>
                  <p>{props.data.settings.text}</p>
                </div>
              </div>
            </div>}


            {/*------------------------------------------------------------------------------------------------------------------------------------
                                                            CUSTOM MESSAGE ALERT
            --------------------------------------------------------------------------------------------------------------------------------------*/}
           {props.data.intent=='warning_message'&&<div className="custom-alert-wrapper" onClick={()=>PassCallback({intent:props.data.settings.onclose?'close':'',reload:props.data.settings.reload})}>
             <div className="custom-alert warning animate__animated animate__slideInDown animate__faster">
               <div><i className="info circle icon"></i></div>
               <div>
                 {props.data.settings.title!=''&&<h1 style={{'margin-bottom':'3px'}}>{props.data.settings.title}</h1>}
                 <p>{props.data.settings.text}</p>
                 {props.data.settings.footer!=''&&<p className="custom-alert-footer">{props.data.settings.footer}</p>}
               </div>
             </div>
           </div>}




      </React.Fragment>
    );

}
