import React,{useState,useEffect} from 'react'
import { useMediaQuery } from 'react-responsive'
import { Label } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL, ActiveMenu } from '../services/redirect'

import SubscribeEmailComponent from './subscribe-email'

import LogoImage from '../assets/images/logo_footer.png'
export default function FooterComponent(props){

  return (
    <React.Fragment>
      <div className="appWrapper-footer">
        <div className="appWrapper-info">
            <div className="appWrapper-grid">

               <div className="appWrapper-info-container">
                  <div className="companyInfo">
                      <a href={WindowURL('/')}><img src={LogoImage} alt="Logo"/></a>
                      <p>Target Healthcare Limited is a UK based pharmaceutical wholesaler supplying generic, branded and unlicensed medicines.</p>
                  </div>
                  <div>
                      <h2>Contact Information</h2>
                      <p style={{'margin-bottom':'10px'}}>Out-of-hours emergency line available 24/7 for urgent enquiries.</p>
                      <p><i className="uil-outgoing-call icon"></i> Fax: 0800 471 5168</p>
                      <p><i className="uil-mobile-vibrate icon"></i> Tel: 0845 618 0036</p>
                      <p><i className="uil-envelope-alt icon"></i> info@target-healthcare.co.uk</p>
                  </div>
                  <div>
                      <h2>Navigation Menu</h2>
                      <p><i className="uil-search-alt icon"></i> <span><a href={WindowURL('/products')}>Search Products</a></span></p>
                      <p><i className="uil-clock-eight icon"></i> <span><a href={WindowURL('/orders')}>Order History</a></span></p>
                      <p><i className="uil-box icon icon"></i> <span><a href={WindowURL('/product-inquiry')}>New Product Inquiry</a></span></p>
                      <p><i className="uil-envelope-edit icon"></i> <span><a href={WindowURL('/contac-us')}>Contact Us</a></span></p>
                  </div>
                  <div>
                      <h2>Next Day Delivery</h2>
                      <p>We offer next day delivery service on the majority of our product lines on orders placed before 5.00pm. Our dedicated customer care team will confirm your order details once received.</p>
                  </div>
               </div>
            </div>
        </div>
        <div className="appWrapper-copyright">
            <div className="appWrapper-grid">
               <div className="appWrapper-copyright-container">
                 <div><p>© 2023 Target Healthcare. All rights reserved</p></div>
                 <div>
                    <span><a href={WindowURL('/')}>Home</a></span> | <span><a href={WindowURL('/contac-us')}>Contact Us</a></span>
                  </div>
               </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  )
}
