import React,{useState,useRef,useEffect} from 'react'
import { Formik, Field } from 'formik'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import { DropdownComponent } from '../../components/options'
import { ValidateForm } from '../../validations/schemaInquiry'


export default function ProductInquiryComponent(props){

  /*----------------------------------------
    DISABLED & ENABLED SCROLL BODY
  -----------------------------------------*/
  function ClassModalBody(data){
    if(data){
      document.body.classList.add("modal");
    }else{
      document.body.classList.remove("modal");
    }
  }


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(false);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      ClassModalBody(false);
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
          HANDLE SUBMIT FORM
  -------------------------------------------------------*/

  const [state, setState] = useState({
    name:'',
    strength:'',
    pharma_form:'',
    pack_size:'',
    no_packs:'',
  });

  function handleSubmitForm(values, props){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:""}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','inquiry');
    formData.append('info',JSON.stringify(values));
    axios.post('/portal/inquiry/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setspinner(false);

      if(obj=='empty_session'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Session Expired!',text:'Please login to your account.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }


      if(obj=='success'){
        setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Inquiry successfully sent.'}});
        setnotify(true);
        setTimeout(function() {
          WindowReload();
        },2000);
        return false;
      }


      if(obj=='error'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }

      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Input Error!',text:'Please correct the required fields.'}});
      setnotify(true);
      setTimeout(function() {
        setnotify(false);
      },2000);

      Object.keys(obj).forEach(function(key) {
        props.setErrors(obj);
      });

    })
    .catch(function (error){
      setloader(false);
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:false,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
    });

  }



  /*---------------------------------------------------------------------------------------------------------------------------
        CUSTOM MESSAGE 
  -----------------------------------------------------------------------------------------------------------------------------*/
  function CustomMessageAlert(page){
    let formData = new FormData();
    formData.append('type','custom_message');
    formData.append('page',page);
    axios.post('/portal/data_message/request.php',formData)
    .then(function (response) {
        let obj     = response.data;
        let message = obj.message;
        if(obj.status=='active'){
          setnofitycontent({intent:'warning_message',settings:{
            onclose:obj.onclose=='Yes'?true:false,
            reload:false,
            title:message.header,
            text:message.body,
            footer:message.footer
          }});
          ClassModalBody(true);
          setnotify(true);
        }
    })
    .catch(function (error){
    });
  }



  useEffect(()=>{
    CustomMessageAlert('inquiry_page');
  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
       {loader&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}


       <div className="appWrapper-data">
          <div className="appWrapper-grid">

         <div className="appWrapper-title flex">
            <div>
              <h2>Product Inquiry</h2>
              <p>Please fill up the required fields. All fields are required.</p>
            </div>
            <div>
               <Button className='BasicButton' size='tiny' content='Refresh'  icon='sync alternate icon' onClick={()=>WindowReload()}/>
            </div>
         </div>

        {/*---------------------------------------------------------
            TABLE LIST SECTION
           --------------------------------------------------------*/}
        {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}


          <div className="FormWrapper">
              <Formik
                  initialValues={state}
                  validationSchema={ValidateForm}
                  onSubmit={handleSubmitForm}
                  render={formProps => {
                  const { values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, touched, dirty, setFieldValue } = formProps
                  return(
                  <Form>
                    <div className="panelForm">
                       <div className="panelForm-body">
                          <Form.Group widths='equal'>
                             <Field name="name">
                             {({ field, form }) => (
                                 <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                   <label>Name</label>
                                   <Input {...field} onChange={handleChange}/>
                                   { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                 </Form.Field>
                             )}
                             </Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                             <Field name="strength">
                             {({ field, form }) => (
                                 <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                   <label>Strength</label>
                                   <Input {...field} onChange={handleChange}/>
                                   { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                 </Form.Field>
                             )}
                             </Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                             <Field name="pharma_form">
                             {({ field, form }) => (
                                 <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                   <label>Pharmaceutical Form</label>
                                   <Input {...field} onChange={handleChange}/>
                                   { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                 </Form.Field>
                             )}
                             </Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                             <Field name="pack_size">
                             {({ field, form }) => (
                                 <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                   <label>Pack size / Volume</label>
                                   <Input {...field} onChange={handleChange}/>
                                   { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                 </Form.Field>
                             )}
                             </Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                             <Field name="no_packs">
                             {({ field, form }) => (
                                 <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                   <label>No of Packs</label>
                                   <Input {...field} onChange={handleChange}/>
                                   { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                 </Form.Field>
                             )}
                             </Field>
                          </Form.Group>
                       </div>
                       <div className="panelForm-footer">
                           <Button disabled={!(isValid&&dirty)} className='PrimaryButton uppercase strong' content='Send Inquiry' onClick={handleSubmit}/>
                       </div>
                    </div>{/*.panelForm*/}

                </Form>
              )}}/>
          </div>

        </div>{/*.appWrapper-grid*/}
      </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
