import * as Yup from 'yup'
export function ValidateForm(values) {
    return Yup.object().shape({
        name: Yup.string().required('This field is required'),
        email: Yup.string().email('Please enter a valid email').required('This field is required'),
        contact_number: Yup.string().required('This field is required'),
        subject: Yup.string().required('This field is required'),
        message: Yup.string().required('This field is required'),
    });
}
