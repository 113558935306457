import React,{useState,useRef,useEffect} from 'react'
import { Formik, Field } from 'formik'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import { DropdownComponent } from '../../components/options'

export default function OrdersComponent(props){

  /*----------------------------------------
    DISABLED & ENABLED SCROLL BODY
  -----------------------------------------*/
  function ClassModalBody(data){
    if(data){
      document.body.classList.add("modal");
    }else{
      document.body.classList.remove("modal");
    }
  }



  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(false);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      ClassModalBody(false);
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
    if(data.intent=='confirmation'){
      ProcessRequestDocuments(data.id);
    }
  }


  /*----------------------------------------
    LIST DATA TABLE
  -----------------------------------------*/
  const buttonEl = useRef(null);
  const [buttonclick, setbuttonClick] = useState(0);
  function buttonSubmit(arg){
      setbuttonClick(arg);
      buttonEl.current.click();
  }
  function handleSubmitForm(values, props){
    if(buttonclick==1){
      props.resetForm();
      TableList(pagenumber,search);
    }else{
      let value={
        product_name:values.product_name,
        order_number:values.order_number,
        product_code:values.product_code,
        status:values.status,
        date_order_from:values.date_order_from!=''?moment(new Date(values.date_order_from)).format('YYYY-MM-DD'):'',
        date_order_to:values.date_order_to!=''?moment(new Date(values.date_order_to)).format('YYYY-MM-DD'):''
      }
      TableList(pagenumber,value);
    }
  }
  function ReloadList(){
    let value={
      product_name:'',
      order_number:'',
      product_code:'',
      status:'',
      date_order_from:'',
      date_order_to:'',
    }
    TableList(pagenumber,value);
  }

  const [search, setsearch]  = useState({
      product_name:'',
      order_number:'',
      product_code:'',
      status:'',
      date_order_from:'',
      date_order_to:'',
      date_order_to_min:''
	});
  const [list, setlist] = useState([]);
	const [pagenumber, setpagenumber] = useState(1);
	const [totaldata, settotaldata] = useState(0);
	const [entry, setentry] = useState(0);
	const [entrytype, setentrytype] = useState('');

	const [paginations, setpaginations] = useState({
			paginations:[{
			listnav:[],
			pageinfo:'',
			limit_page:'',
			total_page:'',
			total_records:'',
			current_page:'',
			startPage:'',
			endPage:'',
			ellipseLeft:'',
			ellipseRight:'',
			}]
	});

	  function pagenumberfunction(page){
				TableList(page,search);
		}
		function listreload(){
				TableList(pagenumber,search);
		}
		function searchProcess(){
			  TableList(pagenumber,search);
		}
		function TableList(page,searchstr){

        setcontentspinner({intent:'spinner',settings:{options:'relative',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
        setspinner(true);

				let formData = new FormData();
				formData.append('type','list');
				formData.append('search',JSON.stringify(searchstr));
				formData.append('page',page);
				axios.post('/portal/order-history/request.php',formData)
				.then(function (response) {
						let obj = response.data;
						setentry(obj.entries.val);
						setentrytype(obj.entries.type);
						setlist(obj.listtable);
						setpaginations(obj.paginations);
						settotaldata(obj.paginations.paginations.total_records);
            setspinner(false);
				})
				.catch(function (error){
          setspinner(false);
				});
	}


  /*------------------------------------------------------
          DROPDOWN OPTIONS
  -------------------------------------------------------*/
  const [statusOptions, setStatusDropdown] = useState([]);
  const HandleOptions = async () => {
    const source = await DropdownComponent();
    setStatusDropdown(source.available_status);
  }


  /*------------------------------------------------------
       ADD TO CART to SERVER
  -------------------------------------------------------*/
  function SaveCartServer(){

    let carDataStorage = localStorage.getItem('cart') !== null?JSON.parse(localStorage.getItem('cart')):[];

    if(carDataStorage.length==0){
      return false;
    }

    let formData = new FormData();
    formData.append('type','cart');
    formData.append('info',JSON.stringify(carDataStorage));
    axios.post('/portal/cart/request.php',formData)
    .then(function (response) {
        let obj = response.data;

        if(obj=='empty_session'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Session Expired!',text:'Please login to your account.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }
  
        if(obj=='empty_order'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Empty Cart!',text:'There is no product added on your cart.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }

        if(obj=='error'){
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
        }

    })
    .catch(function (error){
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Add to Cart',text:'Add to cart has a problem. Please contact the administrator.'}});
          setnotify(true);
          setTimeout(function() {
            WindowReload();
            setnotify(false);
          },2000);
          return false;
    });
}

 /*------------------------------------------------------
    ADD TO CART to SERVER END---------
  -------------------------------------------------------*/



  /*----------------------------------------
    REORDER AGAIN PRODUCT
  -----------------------------------------*/
  function SaveCartLocalStorage(data){
    var a = [];
    // Parse the serialized data back into an aray of objects
    a = JSON.parse(localStorage.getItem('cart')) || [];
    // Push the new data (whether it be an object or anything else) onto the array
    a.push(data);
    // Alert the array value
    // Re-serialize the array back into a string and store it in localStorage
    localStorage.setItem('cart', JSON.stringify(a));
  }

  function SuccessAddCart(){
    const cartData = JSON.parse(localStorage.getItem('cart'));
    if(cartData.length>0){
      document.getElementById('item-cart').innerHTML=cartData.length;
    }
    SaveCartServer();
    setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Successfully added to your cart.'}});
    setnotify(true);
    setTimeout(function() {
      setnotify(false);
    },2000);
  }

  function AddToCart(data){
    SaveCartLocalStorage(data);
    SuccessAddCart();
  }


  /*----------------------------------------
    REORDER AGAIN PRODUCT
  -----------------------------------------*/
  function RequestCopyDocuments(data){
    var text  = "Are you sure you want a copy of Invoice? Please click confirm to proceed to the process.";
    if(data.doc==2){
      text    = "Are you sure you want a copy of COC/COA? Please click confirm to proceed to the process.";
    }
    setconfirmcontent({intent:'confirmation',settings:{id:data,centered:false,onclose:true,icon:'warning',title:'Request Confirmation!',text:text}});
    setconfirm(true);
  }
  function ProcessRequestDocuments(data){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:""}});
    setconfirm(false);
    setloader(true);

    let formData = new FormData();
    formData.append('type','requestdoc');
    formData.append('info',JSON.stringify(data));
    axios.post('/portal/order-history/request.php',formData)
    .then(function (response) {
        let obj = response.data;

        setloader(false);

        if(obj=='success'){
          setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Your request successfully sent.'}});
          setnotify(true);
          setTimeout(function() {
            setnotify(false);
          },2000);
        }else{
          setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Request Failed',text:'Request not successfully sent.'}});
          setnotify(true);
          setTimeout(function() {
            setnotify(false);
          },2000);
        }

    })
    .catch(function (error){
      setconfirm(false);
      setloader(false);
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
    });
  }


  /*---------------------------------------------------------------------------------------------------------------------------
        CUSTOM MESSAGE 
  -----------------------------------------------------------------------------------------------------------------------------*/
  function CustomMessageAlert(page){
    let formData = new FormData();
    formData.append('type','custom_message');
    formData.append('page',page);
    axios.post('/portal/data_message/request.php',formData)
    .then(function (response) {
        let obj     = response.data;
        let message = obj.message;
        if(obj.status=='active'){
          setnofitycontent({intent:'warning_message',settings:{
            onclose:obj.onclose=='Yes'?true:false,
            reload:false,
            title:message.header,
            text:message.body,
            footer:message.footer
          }});
          ClassModalBody(true);
          setnotify(true);
        }
    })
    .catch(function (error){
    });
  }


  useEffect(()=>{
    TableList(pagenumber,search);
    HandleOptions();
    CustomMessageAlert('order_page');
  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
       {loader&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

       <div className="appWrapper-data">
          <div className="appWrapper-grid">

        <div className="appWrapper-title">
           <div>
             <h2>List Orders</h2>
             <p>Your products order history</p>
           </div>
        </div>

       {/*---------------------------------------------------------
          SEARCH SECTION
         --------------------------------------------------------*/}
       <Formik
         initialValues={search}
         onSubmit={handleSubmitForm}
         render={formProps => {
         const { values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, touched, setFieldValue } = formProps

         const CustomDateFrom = ({ value, onClick }) => (
           <Input iconPosition='left' onClick={onClick} value={values.date_order_from}><i className='uil-calender icon'></i><input /></Input>
         );
         const CustomDateTo = ({ value, onClick }) => (
           <Input iconPosition='left' onClick={onClick} value={values.date_order_to}><i className='uil-calender icon'></i><input /></Input>
         );

         return(<React.Fragment>
           <div className="search-wrapper">
              <div className="search-body">
                <Form>
                <Form.Group widths="equal">
                    <Field name="product_name">
                        {({ field, form }) => (
                        <Form.Field>
                          <label>Product</label>
                          <Input fluid {...field} onChange={handleChange}  placeholder="Enter word to search"/>
                        </Form.Field>
                        )}
                      </Field>
                      <Field name="order_number">
                          {({ field, form }) => (
                          <Form.Field>
                            <label>Order Number</label>
                            <Input fluid {...field} onChange={handleChange}/>
                          </Form.Field>
                          )}
                      </Field>
                      <Field name="product_code">
                          {({ field, form }) => (
                          <Form.Field>
                            <label>Product Code</label>
                            <Input fluid {...field} onChange={handleChange}/>
                          </Form.Field>
                          )}
                       </Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                       <Field name="date_order_from">
                           {({ field, form }) => (
                           <Form.Field>
                             <label>Date Order From</label>
                             <DatePicker
                               showYearDropdown
                               yearDropdownItemNumber={100}
                               scrollableYearDropdown
                               showMonthDropdown
                               dropdownMode="select"
                               selected={values.date_order_from!=''?new Date(values.date_order_from):null}
                               onChange={date => {
                                  var dateselected 	= moment(new Date(date)).format('DD MMMM YYYY');
                                  var formindate 		= moment(new Date(date)).add(0, 'days');
                                  setFieldValue('date_order_to_min',new Date(formindate));
                                  setFieldValue('date_order_from',dateselected);
                                  setFieldValue('date_order_to',dateselected);
                               }}
                               customInput={<CustomDateFrom />}
                             />

                           </Form.Field>
                           )}
                       </Field>
                       <Field name="date_order_to">
                           {({ field, form }) => (
                           <Form.Field>
                             <label>Date Order To</label>
                             <DatePicker
                               showYearDropdown
                               yearDropdownItemNumber={100}
                               scrollableYearDropdown
                               showMonthDropdown
                               dropdownMode="select"
                               selected={values.date_order_to!=''?new Date(values.date_order_to):null}
                               minDate={values.date_order_to_min}
                               onChange={date => {
                                 var dateselected 		= moment(new Date(date)).format('DD MMMM YYYY');
                                 setFieldValue('date_order_to',dateselected);
                               }}
                               customInput={<CustomDateTo />}
                             />
                           </Form.Field>
                           )}
                       </Field>
                       <Form.Field></Form.Field>
                  </Form.Group>



                 </Form>
              </div>
              <div className="search-footer">
                <Button type="button" icon='sync alternate' size='tiny' className="BasicButton" content='Reset' onClick={() => buttonSubmit(1)}/>
                <Button type="button" icon='search' size='tiny' className="BasicButton" content='Search' onClick={() => buttonSubmit(2)}/>
                <button type="button" style={{'display':'none'}} ref={buttonEl} onClick={handleSubmit}></button>
              </div>
           </div>
        </React.Fragment>)}}/>





      {/*---------------------------------------------------------
          TABLE LIST SECTION
         --------------------------------------------------------*/}
      {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

      {!spinner&&<React.Fragment>
      <div className="tableWrapper">
       <div className="panel-table custom">

         <div className="panel-table-body">
         <div className="table-responsive overflow">
           <Table celled compact='very'>
             <Table.Header>
               <Table.Row>
                 <Table.HeaderCell>Date</Table.HeaderCell>
                 <Table.HeaderCell>Order Number</Table.HeaderCell>
                 <Table.HeaderCell>Product Code</Table.HeaderCell>
                 <Table.HeaderCell>Product</Table.HeaderCell>
                 <Table.HeaderCell>Quantity</Table.HeaderCell>
                 <Table.HeaderCell>Price</Table.HeaderCell>
                 <Table.HeaderCell>Total</Table.HeaderCell>
                 <Table.HeaderCell></Table.HeaderCell>
               </Table.Row>
             </Table.Header>

             <Table.Body>
                   {list.length==0&&<Table.Row negative><Table.Cell  colSpan='10' textAlign="center">No record found</Table.Cell></Table.Row>}
                   {list.map(function(data, key){
                     return <Table.Row key={key} verticalAlign='top' negative={data.status>0}>
                         <Table.Cell className="now-wrapp">
                            <div className="descriptionTable">
                              <div>
                                  <h3>{data.date_order}</h3>
                                  <p>{data.time_order}</p>
                              </div>
                            </div>
                         </Table.Cell>
                         <Table.Cell>{data.order_number}</Table.Cell>
                         <Table.Cell>{data.product_code}</Table.Cell>
                         <Table.Cell>{data.product_name}</Table.Cell>
                         <Table.Cell collapsing>{data.quantity}</Table.Cell>
                         <Table.Cell collapsing>{data.price}</Table.Cell>
                         <Table.Cell collapsing>{data.total}</Table.Cell>
                         <Table.Cell collapsing>
                           <div className="tblButtonEqual">
                              <div><Button className='InfoButton' size='tiny' content='Order Details'  icon='uil-notes icon' as="a" href={WindowURL('/order/details/'+data.id)}/></div>
                              <div><Button className='InfoButton' size='tiny' content='Repeat Order'  icon='repeat icon' onClick={()=>{
                                  if(data.status>0){
                                    setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Error Product',text:"The product is not available. Repeat orders on this product are not available at the moment."}});
                                    setnotify(true);
                                    return false;
                                  }
                                  AddToCart({
                                    id:data.products_id,
                                    quantity:data.quantity,
                                    price:data.sellprice,
                                    code:data.product_code,
                                    name:data.product_name,
                                  })
                              }}/></div>
                           </div>
                           <div className="tblButtonEqual">
                              <div><Button className='GreenButton' size='tiny' content='Copy Invoice'  icon='copy outline icon' onClick={()=>RequestCopyDocuments({id:data.id,doc:1})}/></div>
                              <div><Button className='GreenButton' size='tiny' content='Copy COC/COA'  icon='copy outline icon' onClick={()=>RequestCopyDocuments({id:data.id,doc:2})}/></div>
                           </div>
                         </Table.Cell>
                     </Table.Row>
                   })}
               </Table.Body>
               {list.length>0&&
                 <Table.Footer fullWidth>
                     <Table.Row>
                     <Table.HeaderCell colSpan='8'>
                         <div className="dash-footer"><Paginations callbackPagination={paginations} callbackPagenumber={pagenumberfunction}/></div>
                     </Table.HeaderCell>
                     </Table.Row>
                 </Table.Footer>
               }
           </Table>
          </div>{/*.table-responsive*/}
         </div>{/*.panel-table-body*/}
        </div>{/*.panel-table*/}
        </div>

       </React.Fragment>}

      </div>{/*.appWrapper-grid*/}
    </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
