import React,{useState,useEffect} from 'react'
import axios from 'axios'

export function DropdownComponent(props) {
	async function FunctionOptions() {
	    const response = await axios.post('/include/options.php', 'type=options')
	    return response.data
	}
	return FunctionOptions();
}
