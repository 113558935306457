import React, {useState} from 'react'
import DropzoneComponent from 'react-dropzone-component'

export default function DropzoneUploadPrescription(props) {

	function SetFileImage(file){
		  props.data(file);
	}
	function SetFilePath(file){
		  props.path(file);
	}
	function SetErrorData(file){
		props.error_data(file);
  }

	 var myDropzone;
	 const [dropmessage, setdropmessage]       = useState(true);
	 function initCallback (dropzone) {
		 myDropzone = dropzone;
	 }
	 function removeFile (file) {
		 if (myDropzone) {
		  myDropzone.removeFile(file);
		 }
	 }
	 function disableDropzone(){
		 myDropzone.removeEventListeners();
	 }
	 function enableDropzone(){
		 myDropzone.setupEventListeners();
	 }
	 let componentConfig = {
		 postUrl: 'no-url',
		 addRemoveLinks:true,
	 }

	 let djsConfig = {
		 acceptedFiles : 'image/jpeg, images/jpg, image/png',
		 addRemoveLinks:true,
		 autoProcessQueue: false,
		 parallelUploads : 1,
		 maxFiles:1,
	 }

	 let eventHandlers = {
		 init: function(dropzone) {
			initCallback(dropzone);
		 },
		 addedfile(file){

			SetFileImage(file);
			myDropzone.on("thumbnail", function(file){
				SetFilePath(file.dataURL);
			});
			setdropmessage(false);

			if(file.size>2000000){
				SetErrorData('The image is too large. Allowed maximum size is 2MB.');
				setdropmessage(false);
				removeFile(file);
				enableDropzone();
			}

		 },
		 maxfilesreached(file){
			disableDropzone();
		 },
		 maxfilesexceeded(file){
			setdropmessage(false);
			removeFile(file);
			disableDropzone();
		 },
		 removedfile(file){
			if(myDropzone.getQueuedFiles().length==0){
				setdropmessage(true);
				SetFileImage('');
				enableDropzone();
				//SetFilePath('no_image');
				//setavatar(tempavatar);
			}
			if(file.status=='added'){
				//SetFileImage('');
			}
		 },
		 sending(file, xhr, formData) {
			formData.append('type', 'uploadfile');
		 },
		 error(errorMessage){
			 //console.log(errorMessage);
		 }
	}



	return (
		<React.Fragment>
			<DropzoneComponent
				 className="dropzone"
				 config={componentConfig}
				 eventHandlers={eventHandlers}
				 djsConfig={djsConfig}
				 >
				 {dropmessage&&<div className="dz-message" data-dz-message>
					<div className="dropIcon"><i className="uil-image"></i></div>
					<div className="dropText">Drop or click image here to upload</div>
				 </div>}
			</DropzoneComponent>
	  </React.Fragment>
	)
}
