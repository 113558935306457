import React,{useState,useRef,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import { DropdownComponent } from '../../components/options'

export default function OrdersDetailsComponent(props){

  const params = useParams();

  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(true);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*----------------------------------------
    Handle Details Order
  -----------------------------------------*/
  const [state, setState] = useState({
    id:'',
    date_order:'',
    time_order:'',
    account_type:'',
    order_number:'',
    account_number:'',
    account_name:'',
    product_code:'',
    product_name:'',
    quantity:'',
    price:'',
    total:'',
    date_process:'',
    info_process:'',
    sat_delivery:'',
    message:'',
    account:{
      id:'',
      account_number:'',
      account_name:'',
      email:'',
      address1:'',
      address2:'',
      address3:'',
      phone:'',
      city:'',
      province:'',
      postcode:'',
      country:'',
      activities:'',
      status:'',
      status_pricing:'',
      comments:'',
    },
    address:{},
  });


  function OrderDetails(id){

    setcontentspinner({intent:'spinner',settings:{options:'relative',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Loading',text:""}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','details');
    formData.append('id',id);
    axios.post('/portal/order-history/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setspinner(false);

      if(obj==0){
        WindowLocation('/orders');
        return false;
      }

      setState({
        ...state,
        id:obj.id,
        date_order:obj.date_order,
        time_order:obj.time_order,
        order_number:obj.order_number,
        account_number:obj.account_number,
        account_type:obj.account_type,
        account_name:obj.account_name,
        product_code:obj.product_code,
        product_name:obj.product_name,
        quantity:obj.quantity,
        price:obj.price,
        total:obj.total,
        date_process:obj.date_process,
        info_process:obj.info_process,
        sat_delivery:obj.sat_delivery,
        account:obj.account,
        address:obj.order_address,
        message:obj.message
      });


    })
    .catch(function (error){
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
      setTimeout(function() {
        setnotify(false);
      },3000);
    });
  }


  useEffect(()=>{
    OrderDetails(params.id);
  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}

       <div className="appWrapper-data">
          <div className="appWrapper-grid">

          <div className="appWrapper-title">
             <div>
               <h2>Orders Details</h2>
               <p>Below are the details of the order</p>
             </div>
          </div>

         {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

         {/*---------------------------------------------------------
          CUSTOMER INFORMATION
         --------------------------------------------------------*/}
         {!spinner&&<React.Fragment>
         <div className="panel hasTop">
            <div className="panel-header">
                <h2>Customer Information</h2>
            </div>
            <div className="panel-body">

        
                <Table className="noBorder">
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell collapsing><strong>Account Number</strong></Table.Cell>
                        <Table.Cell>{state.account.account_number}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell collapsing><strong>Account Name</strong></Table.Cell>
                        <Table.Cell>{state.account.account_name}</Table.Cell>
                      </Table.Row>

                      <Table.Row>
                          <Table.Cell><strong>Account Type</strong></Table.Cell>
                          <Table.Cell>{parse(state.account_type)}</Table.Cell>
                      </Table.Row>
                   
                    </Table.Body>
                  </Table>
      

            </div>{/*.panel-body*/}
         </div>{/*.panel*/}


         <div className="panel hasTop">
                <div className="panel-header"><h2>Order Delivery Address</h2></div>
                <div className="panel-body">

                       <Table className="noBorder">
                           <Table.Body>

                             {state.address.pharmacy!=''&&<Table.Row>
                               <Table.Cell><strong>Pharmacy</strong></Table.Cell>
                               <Table.Cell>{state.address.pharmacy}</Table.Cell>
                             </Table.Row>}

                             {state.address.reference!=''&&<Table.Row>
                               <Table.Cell><strong>Reference</strong></Table.Cell>
                               <Table.Cell>{state.address.reference}</Table.Cell>
                             </Table.Row>}

                             <Table.Row>
                               <Table.Cell width={3}><strong>Phone</strong></Table.Cell>
                               <Table.Cell>{state.address.phone}</Table.Cell>
                             </Table.Row>

                             <Table.Row>
                               <Table.Cell><strong>Address Line 1</strong></Table.Cell>
                               <Table.Cell>{state.address.line_1}</Table.Cell>
                             </Table.Row>
                             <Table.Row>
                               <Table.Cell><strong>Address Line 2</strong></Table.Cell>
                               <Table.Cell>{state.address.line_2}</Table.Cell>
                             </Table.Row>
                             <Table.Row>
                               <Table.Cell><strong>Address Line 3</strong></Table.Cell>
                               <Table.Cell>{state.address.line_3}</Table.Cell>
                             </Table.Row>

                             <Table.Row>
                               <Table.Cell><strong>City</strong></Table.Cell>
                               <Table.Cell>{state.address.city}</Table.Cell>
                             </Table.Row>

                             <Table.Row>
                               <Table.Cell><strong>County</strong></Table.Cell>
                               <Table.Cell>{state.address.county}</Table.Cell>
                             </Table.Row>

                             <Table.Row>
                               <Table.Cell><strong>Postal Code</strong></Table.Cell>
                               <Table.Cell>{state.address.postal_code}</Table.Cell>
                             </Table.Row>

                             <Table.Row>
                               <Table.Cell><strong>Country</strong></Table.Cell>
                               <Table.Cell>{state.address.country}</Table.Cell>
                             </Table.Row>
                             
                             </Table.Body>
                         </Table>

            
                </div>{/*.page-form-body*/}
             </div>{/*.page-form-panel*/}


         {/*---------------------------------------------------------
          ORDER DETAILS
         --------------------------------------------------------*/}
         <div className="panel hasTop">
            <div className="panel-header">
                <h2>Order Details</h2>
            </div>
            <div className="panel-body">
              <div className="table-responsive">
                <Table celled compact='very' className="ordersTable">
                  <Table.Header>
                    <Table.Row verticalAlign='middle'>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Order Number</Table.HeaderCell>
                      <Table.HeaderCell>Account Number</Table.HeaderCell>
                      <Table.HeaderCell>Pharmacy</Table.HeaderCell>
                      <Table.HeaderCell>Product Code</Table.HeaderCell>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                      <Table.HeaderCell>Price</Table.HeaderCell>
                      <Table.HeaderCell>Total</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      <Table.Row verticalAlign='top'>
                          <Table.Cell className="now-wrapp">
                             <div className="descriptionTable">
                               <div>
                                   <h3>{state.date_order}</h3>
                                   <p>{state.time_order}</p>
                               </div>
                             </div>
                          </Table.Cell>
                          <Table.Cell>{state.order_number}</Table.Cell>
                          <Table.Cell>{state.account_number}</Table.Cell>
                          <Table.Cell>{state.account_name}</Table.Cell>
                          <Table.Cell>{state.product_code}</Table.Cell>
                          <Table.Cell>{state.product_name}</Table.Cell>
                          <Table.Cell collapsing>{state.quantity}</Table.Cell>
                          <Table.Cell collapsing>{state.price}</Table.Cell>
                          <Table.Cell collapsing>{state.total}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                    {state.sat_delivery==1&&
                      <Table.Footer fullWidth>
                          <Table.Row>
                          <Table.HeaderCell colSpan='9'>
                            <div className="statusWrapperDetails">
                              <div className='alert-message centerFlex left success'>
                                <div><i class="uil-check-circle icon"></i></div>
                                <div>
                                  <p>Saturday Delivery Required.</p>
                                </div>
                              </div>
                            </div>
                          </Table.HeaderCell>
                          </Table.Row>
                      </Table.Footer>
                    }
                 </Table>
                </div>
            </div>
         </div>



         {state.message!=''&&<React.Fragment>
          <div className="panel hasTop">
            <div className="panel-header"><h2>Message</h2></div>
            <div className="panel-body">

                    <Table className="noBorder">
                        <Table.Body>

                          <Table.Row>
                            <Table.Cell>{state.message}</Table.Cell>
                          </Table.Row>


                          </Table.Body>
                      </Table>

        
            </div>{/*.page-form-body*/}
          </div>{/*.page-form-panel*/}
          </React.Fragment>}



         <div className="buttonPageFooter">
            <Button className='PrimaryGreenButton strong' content='Back to order list' icon='arrow left icon' as="a" href={WindowURL('/orders')}/>
         </div>

         </React.Fragment>}


      </div>{/*.appWrapper-grid*/}
    </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
