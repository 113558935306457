import React, { Component } from 'react'
import { Button, Label, Dimmer, Loader, Icon, Modal, Popup } from 'semantic-ui-react'

export default function Spinner(props) {

   function PassCallback(data){
     props.callback(data);
   }

    return (
     <React.Fragment>
        {props.data.intent=='spinner'&&<React.Fragment>
          <div className={'spinner-wrapper '+props.data.settings.options}>
            <Loader active size='large'><h1>{props.data.settings.title}</h1><p>{props.data.settings.text}</p></Loader>
          </div>
        </React.Fragment>}
      </React.Fragment>
    );

}
