import React,{useState,useRef,useEffect} from 'react'
import { Formik, Field } from 'formik'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker"
import Cleave from 'cleave.js/react'
import parse from 'html-react-parser'

import { Form, Input, TextArea, Button, Select, Label, Dropdown, Dimmer, Loader, Icon, Message, Modal, Checkbox, Popup, Table, Segment } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL } from '../../services/redirect'

import Paginations from '../../services/paginations'
import EntryList from '../../services/entries'
import Spinner from '../../services/spinner'
import AlertMessage from '../../services/alert'
import AlertConfirmation from '../../services/confirmation'
import { DropdownComponent } from '../../components/options'
import { ValidateForm } from '../../validations/schemaContact'


export default function ContactUsComponent(props){


  /*----------------------------------------
    DISABLED & ENABLED SCROLL BODY
  -----------------------------------------*/
  function ClassModalBody(data){
    if(data){
      document.body.classList.add("modal");
    }else{
      document.body.classList.remove("modal");
    }
  }


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [loader,setloader] = useState(false);
  const [spinner,setspinner] = useState(true);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }

  /*------------------------------------------------------
    ALERT MESSAGE NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      ClassModalBody(false);
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
    ALERT CONFIRMATION
    {intent:'delete',settings:{id:id,centered:false,onclose:true,icon:'warning',title:'Delete Confirmation!',text:'This process is not ireversible. Once you continue the process, the record will be deleted forever on the database.'}}
  -------------------------------------------------------*/
  const [confirm,setconfirm] = useState(true);
  const [confirmcontent, setconfirmcontent] = useState({});
  function Confirmcallback(data){
    if(data.intent=='close'){
      setconfirm(false);
      if(data.reload){
        WindowReload();
      }
    }
  }



  /*------------------------------------------------------
          HANDLE SUBMIT FORM
  -------------------------------------------------------*/

  const [state, setState] = useState({
    name:'',
    email:'',
    contact_number:'',
    subject:'',
    message:'',
  });

  function handleSubmitForm(values, props){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:""}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','message');
    formData.append('info',JSON.stringify(values));
    axios.post('/portal/contact/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setspinner(false);

      if(obj=='empty_session'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Session Expired!',text:'Please login to your account.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }


      if(obj=='success'){
        setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Message successfully sent.'}});
        setnotify(true);
        setTimeout(function() {
          WindowReload();
        },2000);
        return false;
      }


      if(obj=='error'){
        setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
        setnotify(true);
        setTimeout(function() {
          setnotify(false);
        },2000);
        return false;
      }

      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Input Error!',text:'Please correct the required fields.'}});
      setnotify(true);
      setTimeout(function() {
        setnotify(false);
      },2000);

      Object.keys(obj).forEach(function(key) {
        props.setErrors(obj);
      });

    })
    .catch(function (error){
      setloader(false);
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:false,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
    });

  }


  /*---------------------------------------------------------------------------------------------------------------------------
        CUSTOM MESSAGE 
  -----------------------------------------------------------------------------------------------------------------------------*/
  function CustomMessageAlert(page){
    let formData = new FormData();
    formData.append('type','custom_message');
    formData.append('page',page);
    axios.post('/portal/data_message/request.php',formData)
    .then(function (response) {
        let obj     = response.data;
        let message = obj.message;
        if(obj.status=='active'){
          setnofitycontent({intent:'warning_message',settings:{
            onclose:obj.onclose=='Yes'?true:false,
            reload:false,
            title:message.header,
            text:message.body,
            footer:message.footer
          }});
          ClassModalBody(true);
          setnotify(true);
        }
    })
    .catch(function (error){
    });
  }



  useEffect(()=>{
    CustomMessageAlert('contact_page');
  },[]);

  return (
    <React.Fragment>

       {confirm&&<AlertConfirmation data={confirmcontent} callback={Confirmcallback}/>}
       {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
       {loader&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}


       <div className="appWrapper-data">
          <div className="appWrapper-grid">

         <div className="appWrapper-title flex">
            <div>
              <h2>Contact Us</h2>
              <p>Contact information and message form.</p>
            </div>
            <div>
               <Button className='BasicButton' size='tiny' content='Refresh'  icon='sync alternate icon' onClick={()=>WindowReload()}/>
            </div>
         </div>

        {/*---------------------------------------------------------
            TABLE LIST SECTION
           --------------------------------------------------------*/}
        {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}
          <div className="FormWrapper">
              <Formik
                  initialValues={state}
                  validationSchema={ValidateForm}
                  onSubmit={handleSubmitForm}
                  render={formProps => {
                  const { values, isSubmitting, errors, handleChange, handleBlur, handleSubmit, isValid, touched, dirty, setFieldValue } = formProps
                  return(
                  <Form>

                   <div className="contactWrapper">
                      <div>
                        <div className="panelForm">
                           <div className="panelForm-header">
                              <h2>Contact Information</h2>
                           </div>
                           <div className="panelForm-body">
                              <div className="contactDetails">
                                  <div><i className="map marker alternate icon"></i></div>
                                  <div>
                                      <p>Address</p>
                                      <p style={{'font-weight':'700'}}>Target Healthcare Limited (Glasgow)</p>
                                      <p>8 Redwood Crescent Peel Park East Kilbride Glasgow G74 5PA</p>
                                      <p style={{'font-weight':'700','margin-top':'15px'}}>Target Healthcare Limited (Luton)</p>
                                      <p>32 Bilton Way Luton Bedfordshire LU1 1UU</p>
                                  </div>
                              </div>
                              <div className="contactDetails">
                                  <div><i className="text telephone icon"></i></div>
                                  <div>
                                      <p>Telephone</p>
                                      <p>0845 618 0036</p>
                                  </div>
                              </div>
                              <div className="contactDetails">
                                  <div><i className="fax icon"></i></div>
                                  <div>
                                      <p>Fax</p>
                                      <p>0800 471 5168</p>
                                  </div>
                              </div>
                              <div className="contactDetails">
                                  <div><i className="envelope icon"></i></div>
                                  <div>
                                      <p>Email</p>
                                      <p>info@target-healthcare.co.uk</p>
                                  </div>
                              </div>
                              <div className="contactDetails">
                                  <div><i className="globe icon"></i></div>
                                  <div>
                                      <p>Website</p>
                                      <p>www.target-healthcare.co.uk</p>
                                  </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      <div>
                        <div className="panelForm">
                           <div className="panelForm-header">
                              <h2>Leave a Message</h2>
                           </div>
                           <div className="panelForm-body">
                              <Form.Group widths='equal'>
                                 <Field name="name">
                                 {({ field, form }) => (
                                     <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                       <label>Name</label>
                                       <Input {...field} onChange={handleChange}/>
                                       { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                     </Form.Field>
                                 )}
                                 </Field>
                              </Form.Group>
                              <Form.Group widths="equal">
                                <Field name="email">
                                    {({ field, form }) => (
                                    <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                      <label>Email</label>
                                      <Input {...field} onChange={handleChange} icon="uil-envelope-alt"/>
                                      { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i className="uil-multiply"></i>{form.errors[field.name]}</Label> }
                                    </Form.Field>
                                    )}
                                </Field>
                              </Form.Group>
                              <Form.Group widths='equal'>
                                 <Field name="contact_number">
                                 {({ field, form }) => (
                                     <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                       <label>Contact Number</label>
                                       <Input {...field} onChange={handleChange}/>
                                       { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                     </Form.Field>
                                 )}
                                 </Field>
                              </Form.Group>
                              <Form.Group widths='equal'>
                                 <Field name="subject">
                                 {({ field, form }) => (
                                     <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                       <label>Subject</label>
                                       <Input {...field} onChange={handleChange}/>
                                       { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                     </Form.Field>
                                 )}
                                 </Field>
                              </Form.Group>
                              <Form.Group widths='equal'>
                                 <Field name="message">
                                 {({ field, form }) => (
                                     <Form.Field className={(() => {return form.touched[field.name] && form.errors[field.name] ? 'error' : "";})()}>
                                       <label>Message</label>
                                       <TextArea {...field} onChange={handleChange} rows="3"/>
                                       { form.touched[field.name] && form.errors[field.name] && <Label className="ui above prompt label errorlabel"><i class="fa fa-exclamation-triangle"></i>{form.errors[field.name]}</Label> }
                                     </Form.Field>
                                 )}
                                 </Field>
                              </Form.Group>
                           </div>
                           <div className="panelForm-footer">
                               <Button disabled={!(isValid&&dirty)} className='PrimaryButton uppercase strong' content='Send Message' onClick={handleSubmit}/>
                           </div>
                        </div>{/*.panelForm*/}
                      </div>
                  </div>{/*.contactWrapper*/}

                </Form>
              )}}/>
          </div>

        </div>{/*.appWrapper-grid*/}
      </div>{/*.appWrapper-data*/}

    </React.Fragment>
  )
}
