import React,{useState,useRef,useEffect} from 'react'
import axios from 'axios'
import useOnclickOutside from "react-cool-onclickoutside"
import { Button, Message } from 'semantic-ui-react'

import { WindowReload, WindowURL, WindowLocation, RouteURL, ActiveMenu } from '../services/redirect'
import Spinner from '../services/spinner'
import AlertMessage from '../services/alert'

export default function SidebarMenu(props){

  const handleClickAway = useOnclickOutside(() => CallbackModal({close:true}));
  function CallbackModal(data){
    return false;
    props.callback(data);
  }


  /*----------------------------------------
    SPINNER
  -----------------------------------------*/
  const [spinner,setspinner] = useState(false);
  const [contentspinner, setcontentspinner] = useState({});
  function SpinnerCallback(data){
    if(data.intent=='close'){
      setspinner(false);
      if(data.reload){
        WindowReload();
      }
    }
  }


  /*------------------------------------------------------
    NOTIFY
    {intent:'info',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Data successfully saved.'}}
  -------------------------------------------------------*/
  const [notify,setnotify] = useState(true);
  const [nofitycontent, setnofitycontent] = useState({});
  function Notifycallback(data){
    if(data.intent=='close'){
      setnotify(false);
      if(data.reload){
        WindowReload();
      }
    }
  }


  /*------------------------------------------------------
        HANDLE LOGOUT
  -------------------------------------------------------*/
  function Logout(){

    setcontentspinner({intent:'spinner',settings:{options:'overlay',centered:true,type:'basic',dimmer:'defualt',icon:'spinner',title:'Processing',text:""}});
    setspinner(true);

    let formData = new FormData();
    formData.append('type','logout');
    axios.post('/portal/auth/request.php',formData)
    .then(function (response) {
      let obj = response.data;

      setspinner(false);

      setnofitycontent({intent:'success',settings:{onclose:true,reload:false,title:'Congratulations!',text:'Successfully logout'}});
      setnotify(true);
      setTimeout(function() {
        WindowReload();
        setnotify(false);
      },2000);
      return false;


    })
    .catch(function (error){
      setspinner(false);
      setnofitycontent({intent:'error',settings:{onclose:true,reload:false,title:'Server Error!',text:'Failed to connect through the server.'}});
      setnotify(true);
      setTimeout(function() {setnotify(false);},3000);
    });
  }



   return (
    <React.Fragment>
        <div className="sidebarpanel-wrapper">
             <div className="sidebarpanel-container animate__animated animate__slideInLeft animate__faster" ref={handleClickAway}>

                 {notify&&<AlertMessage data={nofitycontent} callback={Notifycallback}/>}
                 {spinner&&<Spinner data={contentspinner} callback={SpinnerCallback}/>}

                 <div className="sidebarpanel-body">
                    <div className="sidebarpanel-content">

                        <div className="sidebar-menu">
                          <ul>
                              <li className={ActiveMenu('/')?'active':''}><a href={WindowURL('/')}><i className="uil-home-alt"></i><span>Home</span></a></li>
                              <li className={ActiveMenu('products')?'active':''}><a href={WindowURL('/products')}><i className="uil-search-alt"></i><span>Search Products</span></a></li>
                              <li className={ActiveMenu('orders')?'active':''}><a href={WindowURL('/orders')}><i className="uil-clock-eight"></i><span>Order History</span></a></li>
                              <li className={ActiveMenu('product-inquiry')?'active':''}><a href={WindowURL('/product-inquiry')}><i className="uil-box"></i><span>Product Inquiry</span></a></li>
                              <li className={ActiveMenu('contact-us')?'active':''}><a href={WindowURL('/contact-us')}><i className="uil-envelope-edit"></i><span>Contact Us</span></a></li>
                          </ul>
                        </div>{/*.sidebar-menu*/}

                    </div>{/*.sidebarpanel-content*/}
                </div>{/*.sidebarpanel-body*/}

                <div className="sidebarpanel-footer">
                    <span><i className="uil-user"></i></span>
                    <span><Button className="SideBarButton uppercase strong" content='Logout' icon='sign-out' size='tiny' labelPosition='right' onClick={()=>Logout()}/></span>
                </div>

             </div>
           </div>
    </React.Fragment>
  )
}
