import React,{useState,useEffect} from 'react'
import { useMediaQuery } from 'react-responsive'
import { Label } from 'semantic-ui-react'
import { WindowReload, WindowURL, WindowLocation, RouteURL, ActiveMenu, HomeActiveMenu } from '../services/redirect'

import SidebarMenu from './sidebar-menu'
import AccountComponent from '../pages/Account/account'
import LogoImage from '../assets/images/logo.png'

export default function HeaderMenuComponent(props){

  function ClassModalBody(data){
    if(data){
      document.body.classList.add("modal");
    }else{
      document.body.classList.remove("modal");
    }
  }

  const [sidebar, setSidebar] = useState(false);
  const [account, setAccount] = useState(false);

  function SidebarFunction(data){
    if(data){
      setSidebar(false);
      ClassModalBody(false);
    }else{
      setSidebar(true);
      ClassModalBody(true);
    }
  }

  function SidebarProfile(data){
    if(data){
      setAccount(true);
      ClassModalBody(true);
    }else{
      setAccount(false);
      ClassModalBody(false);
    }
  }
  function CallbackFunction(data){
    setAccount(false);
    ClassModalBody(false);
  }


  const [cart, setCart] = useState(0);
  function CartFunction(){
    if (localStorage.getItem('cart') !== null) {
      const cartData = JSON.parse(localStorage.getItem('cart'));
      if(cartData.length>0){
        setCart(cartData.length);
      }
    }
  }

  useEffect(()=>{
    CartFunction();
  },[]);

  return (
    <React.Fragment>

    <div className="appWrapper-header">

  
        <div className="appWrapper">

          <div className="appWrapper-mobile-logo">
              <img src={LogoImage} alt="Logo"/>
          </div>

          <div className="appWrapper-header-container">
             <div className={sidebar?'appWrapper-logo open':'appWrapper-logo close'}>
                <a href={WindowURL('/')}><img src={LogoImage} alt="Logo"/></a>
                <span className="hamburger" onClick={()=>SidebarFunction(sidebar)}><i className={sidebar?'flaticon-multiply':'flaticon-menu-2'}></i></span>
             </div>
             <div className="appWrapper-user">
               <ul>
                  <li>
                    <a href={WindowURL('/cart')}>
                      <div className="appWrapper-user-divider">
                        <div><i className="flaticon-shopping-cart"></i></div>
                        <div>
                            <div className="item-cart"><span id="item-cart">{cart}</span></div>
                            <div>Cart</div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li onClick={()=>SidebarProfile(true)}>
                    <a>
                      <div className="appWrapper-user-divider">
                        <div><i className="flaticon-user-2"></i></div>
                        <div>
                            <div>Welcome,</div>
                            <div style={{'font-weight':'700'}}>{props.data.short_name}</div>
                        </div>
                      </div>
                    </a>
                  </li>
               </ul>

             </div>
           </div>
        </div>
    </div>

    <div className="appWrapper-menu">
       <div className="appWrapper">
           <ul>
              <li className={HomeActiveMenu('')?'active':''}>
                <a href={WindowURL('/')}>
                  <span><i className="uil-home-alt icon"></i></span>
                  <span>Home</span>
                 </a>
              </li>
              <li className={ActiveMenu('products')?'active':''}>
                <a href={WindowURL('/products')}>
                  <span><i className="uil-search-alt icon"></i></span>
                  <span>Search Products</span>
                 </a>
              </li>
              <li className={(ActiveMenu('orders')||ActiveMenu('order'))?'active':''}>
                <a href={WindowURL('/orders')}>
                  <span><i className="uil-clock-eight icon"></i></span>
                  <span>Order History</span>
                 </a>
              </li>
              <li className={ActiveMenu('product-inquiry')?'active':''}>
                <a href={WindowURL('/product-inquiry')}>
                  <span><i className="uil-box icon"></i></span>
                  <span>New Product Inquiry</span>
                 </a>
              </li>
              <li className={ActiveMenu('contact-us')?'active':''}>
                <a href={WindowURL('/contact-us')}>
                  <span><i className="uil-envelope-edit icon"></i></span>
                  <span>Contact Us</span>
                 </a>
              </li>
           </ul>
       </div>
    </div>

    {sidebar&&<SidebarMenu />}
    {account&&<AccountComponent data={props.data} callback={CallbackFunction}/>}

    </React.Fragment>
  )
}
