import React, { Component } from 'react'
import { Button, Label, Dimmer, Loader, Icon, Modal, Popup } from 'semantic-ui-react'
import Success from '../assets/images/alert/success.png'
import Error from '../assets/images/alert/error.png'
import Warning from '../assets/images/alert/warning.png'
import Info from '../assets/images/alert/info.png'
export default function AlertConfirmation(props) {

   function PassCallback(data){
     props.callback(data);
   }

    return (
     <React.Fragment>


        {/*-----------------------------------------------------------
          INTENT DELETE CONFIRMATION
          ----------------------------------------------------------*/}
         {props.data.intent=='delete'&&<Modal className="animate__animated animate__slideInDown animate__faster" size={'mini'} open={'open'} centered={props.data.settings.centered} onClose={()=>PassCallback({intent:props.data.settings.onclose?'close':''})}>
           <Modal.Content className="alert-content-wrapper" scrolling>
             <img src={props.data.settings.icon=='warning'?Warning:props.data.settings.icon=='info'?Info:Error}/>
             <h1>{props.data.settings.title}</h1>
             <p>{props.data.settings.text}</p>
           </Modal.Content>
           <Modal.Actions className="alert-footer-wrapper">
              <Button className="RedButton" size='small' icon='close' content='No, Cancel' onClick={()=>PassCallback({intent:'close'})}/>
              <Button className="GreenButton" size='small' icon='check circle' content='Yes, Confirm' onClick={()=>PassCallback({intent:'confirm',id:props.data.settings.id})}/>
           </Modal.Actions>
         </Modal>}

         {props.data.intent=='confirmation'&&<Modal className="animate__animated animate__slideInDown animate__faster" size={'mini'} open={'open'} centered={props.data.settings.centered} onClose={()=>PassCallback({intent:props.data.settings.onclose?'close':''})}>
           <Modal.Content className="alert-content-wrapper" scrolling>
             <img src={props.data.settings.icon=='warning'?Warning:props.data.settings.icon=='info'?Info:Error}/>
             <h1>{props.data.settings.title}</h1>
             <p>{props.data.settings.text}</p>
           </Modal.Content>
           <Modal.Actions className="alert-footer-wrapper">
              <Button className="RedButton" size='small' icon='close' content='No, Cancel' onClick={()=>PassCallback({intent:'close'})}/>
              <Button className="GreenButton" size='small' icon='check circle' content='Yes, Confirm' onClick={()=>PassCallback({intent:'confirmation',id:props.data.settings.id})}/>
           </Modal.Actions>
         </Modal>}



      </React.Fragment>
    );

}
